



















































































































































import { Vue, Component, Prop, Watch, InjectReactive } from 'vue-property-decorator';
import { WidgetActionTypeEnum } from '@/models/form/WidgetActionModel';
import { WidgetData } from '@/models/form/WidgetModel';
import { getCurrentDataSourceConfig, getFormWidgetMap, getFormWidgetTree } from '../utils';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import EleDataSourceConfigDialog from './ele-data-source-config-dialog.vue';
import EleDefaultValueConfig from './ele-default-value-config.vue';
import { WidgetDataSourceConfigData, WidgetDefaultDataConfigData } from '@/models/form/WidgetDataSourceModel';
import { translateText } from '@/utils/translateText';
@Component({
  name: 'EleTriggerActions',
  components: { EleDataSourceConfigDialog, EleDefaultValueConfig }
})
export default class extends Vue {
  @Prop({
    default: () => []
  })
  triggers!: any[];
  widgetActionTypeEnum: any = WidgetActionTypeEnum;
  triggerType: string = 'COMPONENTS';
  currentEffectWidget: any = SystemUtil.cloneDeep(WidgetData);
  currentEffectWidgetIndex: number = -1;
  operationAuthList: any[] = [
    {
      id: 'default',
      label: this.$t('lang_default')
    },
    {
      id: 'required',
      label: this.$t('lang_required')
    },
    {
      id: 'disabled',
      label: this.$t('lang_disable_')
    },
    {
      id: 'hidden',
      label: this.$t('lang_hide')
    }
  ];
  rule: Record<string, any> = {
    compCode: [{ required: true, message: this.$t('lang_components'), trigger: 'blur' }]
  };
  affectedComponents: any[] = [];
  showEffectWidgetDataSourceDialog: boolean = false;

  showEffectWidgetDefaultValueDialog: boolean = false;

  @InjectReactive()
  formId: any;

  get _formWidgetTree() {
    return getFormWidgetTree(this.formId);
  }

  get _formWidgetMap() {
    return getFormWidgetMap(this.formId);
  }

  mounted() {
    this.buildEchoData();
  }

  formWidgetNormalizer(node) {
    return {
      id: node.compCode,
      label: translateText(node.label)
    };
  }
  validateAllForm() {
    if (this.$refs.affectedForm) {
      return new Promise((resolve, reject) => {
        Promise.all(
          (<any[]>this.$refs.affectedForm).map(form => {
            return form.validate();
          })
        ).then(result => {
          if (result.indexOf(false) !== -1) {
            reject(false);
          } else {
            resolve(true);
          }
        });
      });
    }
    this.$message.warning(this.$t('lang_perfect_param_config') as string);
    return false;
  }

  /**
   * 获取组件数据源
   */
  getDataSourceConfigs(effectItem) {
    let widget = effectItem.widget;
    let dataSourceTypes: any[] = [];
    if (widget) {
      let dataSourceStruct = this.$store.getters.dataSourceStruct;
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let compType = widget.compType;
      let dataSourceTypeKeys = widgetConfigMapping.find(item => item.type == compType).dataSourceConfigTypes;
      if (dataSourceTypeKeys) {
        dataSourceTypeKeys.forEach(typeKey => {
          let tmp = {
            type: dataSourceStruct[typeKey].type,
            name: dataSourceStruct[typeKey].name
          };
          dataSourceTypes.push(tmp);
        });
      }
    }
    return dataSourceTypes;
  }

  // 默认值配置类型
  getDefaultDataConfigs(effectItem) {
    let dataSourceTypes: any[] = [];
    let widget = effectItem.widget;
    if (widget && widget.compCode) {
      let defaultDataStruct = this.$store.getters.defaultDataStruct;
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let compType = widget.compType;
      let widgetConfigStruct = widgetConfigMapping.find(item => item.type == compType);
      if (widgetConfigStruct && widgetConfigStruct.defaultDataConfigTypes) {
        widgetConfigStruct.defaultDataConfigTypes.forEach(typeKey => {
          dataSourceTypes.push(defaultDataStruct[typeKey]);
        });
      }
    }
    return dataSourceTypes;
  }

  /**
   * 构造回显数据
   */
  buildEchoData() {
    let result: any = [];
    let affectedComponents = [];
    this.triggers.forEach(item => {
      if (item.type == this.widgetActionTypeEnum.COMPONENTS) {
        affectedComponents = item.affectedComponents || [];
      }
    });
    affectedComponents.forEach((compItem: any) => {
      let dataSourceConfigType = null;
      if (compItem.dataSource.dataSourceConfig) {
        dataSourceConfigType = compItem.dataSource.dataSourceConfig.type;
      }

      let defaultDataConfigType = null;
      if (compItem.dataSource.defaultDataConfig) {
        defaultDataConfigType = compItem.dataSource.defaultDataConfig.type;
      }

      let effectItem = {
        id: SystemUtil.uuid(),
        compCode: compItem.compCode,
        dataSourceConfigType: dataSourceConfigType,
        defaultDataConfigType: defaultDataConfigType,
        operation: compItem.properties.operation,
        widget: SystemUtil.cloneDeep(compItem)
      };
      result.push(effectItem);
    });
    this.affectedComponents = result;
  }

  // 添加关联的组件
  addAffectedComponents(index, affectedComponents) {
    let curAffectedComponents = SystemUtil.cloneDeep(affectedComponents);
    let effectItem: any = {
      id: SystemUtil.uuid(),
      compCode: null,
      widget: SystemUtil.cloneDeep(WidgetData),
      dataSourceConfigType: null,
      defaultDataConfigType: '',
      operation: ''
    };
    curAffectedComponents.splice(index, 0, effectItem);
    this.affectedComponents = curAffectedComponents;
    // affectedComponents.push(effectItem);
  }

  /**
   * 判断数据源是否可配置
   * @param {number} param
   * @returns {number}
   */
  judgeDataSourceConfigable(affectedItem) {
    let widget = affectedItem.widget;
    if (widget && widget.compCode) {
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let compType = widget.compType;
      let widgetConfigStruct = widgetConfigMapping.find(item => item.type == compType);
      if (widgetConfigStruct && widgetConfigStruct.dataSourceConfigTypes) {
        return true;
      }
    }
    return false;
  }

  /**
   * 判断数据源是否可配置
   * @param {number} param
   * @returns {number}
   */
  judgeDefaultDataConfigable(affectedItem) {
    let widget = affectedItem.widget;
    if (widget && widget.compCode) {
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let widgetConfigStruct = widgetConfigMapping.find(item => item.type == widget.compType);
      if (widgetConfigStruct && widgetConfigStruct.defaultDataConfigTypes) {
        if (widgetConfigStruct && widgetConfigStruct.dataSourceConfigTypes) {
          // 如果需要配置数据源
          if (widget.dataSource.dataSourceConfig) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * 判断默认值的类型 表达式或者固定值
   * @param {number} param
   * @returns {number}
   */
  judgeWidgetType(affectedItem, type) {
    return affectedItem.widget && affectedItem.widget.compType == type;
  }

  /**
   * 移除影响的组件
   * @param {number} param
   * @returns {number}
   */
  removeAffectedComponents(affectedComponents, index) {
    affectedComponents.splice(index, 1);
  }

  // 改变受影响的组件时
  changeEffectWidget(affectedItem, affectedComponents, affectedItemIndex) {
    // 获取受影响的组件
    let affectedItemClone = SystemUtil.cloneDeep(affectedItem);
    if (affectedItemClone.compCode) {
      this.currentEffectWidget = affectedItemClone;
      this.currentEffectWidgetIndex = affectedItemIndex;
      let effectWidget: any = this.getWidgetByCode(affectedItemClone.compCode);
      affectedItemClone.widget = effectWidget;
      affectedItemClone.dataSourceConfigType = '';
      affectedItemClone.defaultDataConfigType = '';

      if (this.judgeDataSourceConfigable(affectedItemClone)) {
        // 如果可以配置数据源
        let currentDataSourceConfig = getCurrentDataSourceConfig(effectWidget);
        if (currentDataSourceConfig) {
          // 且受影响组件已经配置了数据源，则把已配置的数据源类型复制给actionType
          affectedItemClone.dataSourceConfigType = currentDataSourceConfig.type;
        } else {
          // 否则默认为空
          affectedItemClone.dataSourceConfigType = '';
          affectedItemClone.widget.dataSource = effectWidget.dataSource;
        }
      }

      if (this.judgeDefaultDataConfigable(affectedItemClone)) {
        // 如果可以配置默认值
        if (effectWidget.dataSource.defaultDataConfig) {
          // 且受影响组件已经配置了默认值
          let currentDefaultDataConfig = effectWidget.dataSource.defaultDataConfig;
          affectedItemClone.defaultDataConfigType = currentDefaultDataConfig.type;
        } else {
          affectedItemClone.defaultDataConfigType = '';
        }
      }
    } else {
      affectedItemClone.dataSourceConfigType = '';
      affectedItemClone.defaultDataConfigType = '';
      affectedItemClone.widget = null;
    }
    affectedComponents.splice(affectedItemIndex, 1, affectedItemClone);
  }

  /**
   * 根据compCode获取组件
   * @param {string} 根据compCode获取组件
   * @returns {}
   */
  getWidgetByCode(compCode) {
    return SystemUtil.cloneDeep(this._formWidgetMap[compCode]);
  }

  // 修改被影响组件的数据源配置类型
  openDataSourceConfigDialog(option, affectedItem, affectedItemIndex, affectedComponents) {
    let dataSourceConfig = SystemUtil.cloneDeep(affectedItem.widget.dataSource.dataSourceConfig);
    if (dataSourceConfig && dataSourceConfig.type == option.type) {
      affectedItem.widget.dataSource.dataSourceConfig = dataSourceConfig;
    } else {
      affectedItem.widget.dataSource.dataSourceConfig = Object.assign(WidgetDataSourceConfigData, option);
    }
    this.currentEffectWidget = SystemUtil.cloneDeep(affectedItem);
    this.currentEffectWidgetIndex = affectedItemIndex;
    this.$nextTick(() => {
      this.showEffectWidgetDataSourceDialog = true;
    });
  }

  /**
   * description  配置数据源弹框 确定
   * @param {}
   * @returns {}
   */
  changeEffectWidgetDataSourceConfig(widget) {
    let currentEffectWidget = SystemUtil.cloneDeep(this.currentEffectWidget);
    currentEffectWidget.widget.dataSource.dataSourceConfig = widget.dataSource.dataSourceConfig;
    this.currentEffectWidget = currentEffectWidget;
    this.affectedComponents.splice(this.currentEffectWidgetIndex, 1, SystemUtil.cloneDeep(currentEffectWidget));
  }

  openDefaultDataConfigDialog(option, affectedItem, affectedItemIndex, affectedComponents) {
    let affectedItemClone = SystemUtil.cloneDeep(affectedItem);
    if (!affectedItemClone.widget.dataSource.defaultDataConfig) {
      affectedItemClone.widget.dataSource.defaultDataConfig = SystemUtil.cloneDeep(WidgetDefaultDataConfigData);
    } else {
    }
    let defaultDataConfig = affectedItemClone.widget.dataSource.defaultDataConfig;
    if (option.type != defaultDataConfig.type) {
      defaultDataConfig.formula = null;
      defaultDataConfig.simpleDefaultDataList = [];
      defaultDataConfig.complexDefaultDataList = [];
      defaultDataConfig.type = affectedItem.defaultDataConfigType;
    }
    affectedItemClone.widget.dataSource.defaultDataConfig = defaultDataConfig;
    this.currentEffectWidget = SystemUtil.cloneDeep(affectedItemClone);
    this.currentEffectWidgetIndex = affectedItemIndex;
    this.$nextTick(() => {
      this.showEffectWidgetDefaultValueDialog = true;
    });
  }

  /**
   * description  配置默认值弹框 确定
   * @param {}
   * @returns {}
   */
  changeEffectWidgetDefaultValueConfig() {
    let defaultValueConfig = (this.$refs.defaultValueConfigRef[0] as any).getDefaultValueConfig();
    if (defaultValueConfig) {
      this.currentEffectWidget.defaultDataConfigType = defaultValueConfig.type;
      this.currentEffectWidget.widget.dataSource.defaultDataConfig = defaultValueConfig;
    } else {
      this.currentEffectWidget.defaultDataConfigType = null;
      delete this.currentEffectWidget.widget.dataSource.defaultDataConfig;
    }
    this.affectedComponents.splice(this.currentEffectWidgetIndex, 1, SystemUtil.cloneDeep(this.currentEffectWidget));
    this.$nextTick(() => {
      this.showEffectWidgetDefaultValueDialog = false;
    });
  }

  // 修改被影响组件的操作权限
  changeEffectWidgetOperationAuth(auth, affectedItem, affectItemIndex, components) {
    let affectedItemClone = SystemUtil.cloneDeep(affectedItem);
    affectedItemClone.widget.properties.operation = auth;
    components.splice(affectItemIndex, 1, affectedItemClone);
  }

  /**
   * 获取配置结果
   * @returns {componentsTrigger,apiTrigger,linkTrigger}
   */
  getActionsResult() {
    let triggers = SystemUtil.cloneDeep(this.triggers);
    return triggers.map(item => {
      if (item.type == this.widgetActionTypeEnum.COMPONENTS) {
        item.affectedComponents = this.buildActionTriggers(this.affectedComponents);
      }
      return item;
    });
  }

  buildActionTriggers(affectedComponents) {
    return affectedComponents.map(item => item.widget);
  }

  @Watch('triggers')
  triggersWatcher() {
    this.buildEchoData();
  }
}
