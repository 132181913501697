






















































































































































































































import { Vue, Component, Prop, InjectReactive, Watch } from 'vue-property-decorator';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import DictConstant from '@/const/DictConstant';
import {
  WidgetActionTypeEnum,
  WidgetTriggerConditionData,
  WidgetTriggerConditionGroupData,
  WidgetTriggerConditionGroupModel,
  WidgetTriggerGroupsData,
  WidgetTriggerGroupsModel,
  WidgetTriggersData
} from '@/models/form/WidgetActionModel';
import { WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import { getBusinessDataSource, getWidgetConditions } from '@/api/form-design/FormApi';
import { getCompares, getCurrentDataSourceConfig, getFormDataSourceMap, getFormWidgetMap } from '../utils';
import EleTriggerActions from './ele-trigger-actions.vue';
import { WidgetDataSourceConfigTypeEnum } from '@/models/form/WidgetDataSourceModel';
import { translateText } from '@/utils/translateText';
@Component({
  name: 'EleTriggerConfig',
  components: { EleTriggerActions }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  triggerGroups!: WidgetTriggerGroupsModel[];
  @Prop()
  triggerType!: string;
  triggerGroupsClone: WidgetTriggerGroupsModel[] = [];
  widgetActionTypeEnum: any = WidgetActionTypeEnum;
  widgetDataTypeEnum: any = WidgetDataTypeEnum;
  dictConstant: any = DictConstant;
  curActionTab: string = WidgetActionTypeEnum.COMPONENTS;
  conditionFields: any[] = [];
  rule: Record<string, any[]> = {
    code: [{ required: true, validator: this.validConditionItem, trigger: 'blur' }],
    compare: [{ required: true, message: this.$t('lang_please_select_an_operator'), trigger: 'change' }],
    result: [{ required: true, validator: this.validResult, trigger: 'blur' }]
  };
  valueTypes: any[] = [
    { id: 'INPUT', label: this.$t('lang_input_value') },
    { id: 'SELECT', label: this.$t('lang_condition_value') }
  ];

  treeLoading: boolean = false;

  @InjectReactive() formId: any;

  get _dataSourceMaps() {
    return getFormDataSourceMap(this.formId);
  }
  get _dataSourceStruct() {
    return this.$store.getters.dataSourceStruct;
  }
  // 获取reality全局默认映射关系
  get _widgetRealitysMap() {
    return this.$store.getters.widgetRealitysMap;
  }

  get _actionStruct(): any[] {
    return this.$store.getters.actionStruct;
  }

  get _conditionFields() {
    let dataSource: any[] = [];
    if (this._dataSourceMaps) {
      this._dataSourceMaps.forEach(group => {
        if (group.valueType != 'FIXED_VALUE') {
          dataSource = dataSource.concat(group.data);
        }
      });
    }

    return dataSource;
  }

  get _formWidgetMap() {
    return getFormWidgetMap(this.formId);
  }

  created() {
    this.echoConfigData();
  }
  validConditionItem(field, value, cb) {
    if (value.value) {
      console.log('参数或者组件', value);
      cb();
    } else {
      cb(new Error('请选择组件或参数'));
    }
  }
  validResult(field, value, cb) {
    if (value) {
      cb();
    } else {
      cb(new Error('请选择值类型'));
    }
  }
  /**
   * 回显
   * @param {number} param
   * @returns {number}
   */
  echoConfigData() {
    this.treeLoading = false;
    this.triggerGroupsClone = SystemUtil.cloneDeep(this.triggerGroups);
    this.triggerGroupsClone.forEach((configGroup, configGroupIndex) => {
      let conditionGroups = configGroup.conditionGroups;
      conditionGroups.forEach((conditionGroup, condGroupIndex) => {
        let conditions: any[] = conditionGroup.conditions;
        conditions.forEach((conditionItem, conditionIndex) => {
          if (!conditionItem.result) {
            this.$set(conditionItem, 'result', null);
          }
          if (conditionItem.value) {
            // 构建每行条件的值的回显
            if (conditionItem.source == 'SELECT') {
              let result: any[] = conditionItem.value.map(item => {
                return item.value;
              });
              conditionItem.result = result;
            } else if (conditionItem.source == 'INPUT') {
              let result: any = '';
              if (conditionItem.value[0]) {
                result = conditionItem.value[0].value;
              }
              conditionItem.result = result;
            }
          } else {
            conditionItem.result = null;
          }
          // 组织条件数据源类型
          this.buildWidgetConditions(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
        });
      });
    });
    setTimeout(() => {
      this.treeLoading = true;
    }, 1000);
  }

  /**
   * 添加触发器组
   * @param {number} param
   * @returns {number}
   */
  async addTriggerGroup(groupItem?: WidgetTriggerGroupsModel, groupIndex = 0) {
    let defaultTriggerGroups = this._actionStruct[this.triggerType].triggerGroups[0];
    let tmp = SystemUtil.cloneDeep(defaultTriggerGroups);
    tmp.id = SystemUtil.uuid();
    if (!groupItem) {
      this.triggerGroupsClone.push(tmp);
    } else if (await this.validateTriggerGroup(false, groupIndex)) {
      0;
      this.triggerGroupsClone.splice(groupIndex + 1, 0, tmp);
    }
  }
  /**
   * 校验配置组
   * @param {number} param
   * @returns {number}
   */
  validateTriggerGroup(needOne: boolean = false, groupIndex: number) {
    //   上方条件组的form
    let conditionForms = Object.keys(this.$refs).filter(key => {
      let reg = new RegExp('ruleForm_' + groupIndex);
      return reg.test(key) && this.$refs[key][0];
    });
    // 异步校验所有的组件form以及条件form
    if (this.$refs.triggerActionsRef) {
      return new Promise(async (resolve, reject) => {
        Promise.all(
          (this.$refs.triggerActionsRef as any[])
            .map(trigger => {
              return trigger.validateAllForm();
            })
            .concat(
              conditionForms.map(key => {
                return this.$refs[key][0].validate();
              })
            )
        )
          .then(result => {
            if (!result.length || result.indexOf(false) >= 0) {
              reject(false);
            } else {
              resolve(true);
            }
          })
          .catch(e => {
            reject(false);
          });
      });
    } else if (needOne) {
      return Promise.reject();
    } else {
      return true;
    }
  }
  validateAllGroup() {
    return new Promise((resolve, reject) => {
      this.triggerGroupsClone.forEach(async (trigger, index) => {
        let result = await this.validateTriggerGroup(true, index);
        if (!result) {
          reject(result);
        }
        if (index === this.triggerGroupsClone.length - 1) {
          resolve(true);
        }
      });
    });
  }
  /**
   * 移除配置组
   * @param {number} param
   * @returns {number}
   */
  removeTriggerGroup(groupIndex = 0) {
    this.triggerGroupsClone.splice(groupIndex, 1);
  }

  /**
   * 添加条件组
   * @param {number} param
   * @returns {number}
   */
  async addConditionGroup(groupItem: any, groupList: WidgetTriggerConditionGroupModel[], groupIndex = 0, configGroupIndex = 0) {
    let tmp = SystemUtil.cloneDeep(WidgetTriggerConditionGroupData);
    tmp.key = SystemUtil.uuid();
    if (!groupItem) {
      tmp.first = true;
      groupList.push(tmp);
    } else if (await this.validateConditionGroup(groupItem, groupIndex, configGroupIndex)) {
      tmp.first = false;
      groupList.splice(groupIndex + 1, 0, tmp);
    }
  }
  /**
   * 校验条件组
   * @param {number} param
   * @returns {PromiseList<boolean>|false}
   */
  validateConditionGroup(groupItem: any, condGroupIndex, configGroupIndex): PromiseLike<boolean> | boolean {
    if (groupItem.conditions.length) {
      let reg = new RegExp('ruleForm_' + configGroupIndex + '-' + condGroupIndex + '-\\d');
      let keys: string[] = Object.keys(this.$refs).filter(a => {
        return reg.test(a) && this.$refs[a] && this.$refs[a][0];
      });
      return Promise.all(
        keys.map(key => {
          return this.$refs[key][0].validate();
        })
      )
        .then(results => {
          if (results.every(result => result === true)) {
            return true;
          } else {
            return false;
          }
        })
        .catch(e => {
          return false;
        });
    } else {
      this.$message.warning(this.$t('lang_perfect_param_config') as string);
      return false;
    }
  }

  /**
   * 移除条件组
   * @param {number} param
   * @returns {number}
   */
  removeConditionGroup(groupList: WidgetTriggerConditionGroupModel[], groupIndex = 0) {
    groupList.splice(groupIndex, 1);
  }

  // 添加规则条件
  async addCondition(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    try {
      if ((await this.validateCond(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex)) || conditionItem == null) {
        let tmp = SystemUtil.cloneDeep(WidgetTriggerConditionData);
        if (conditionItem == null) {
          tmp.first = true;
        } else {
          tmp.first = false;
        }
        conditions.splice(conditionIndex + 1, 0, tmp);
      }
    } catch (e) {
      console.log('valid-error', e);
    }
  }
  // 移除该条件
  removeCondition(conditions, conditionIndex) {
    conditions.splice(conditionIndex, 1);
    this.$forceUpdate();
  }
  // 校验条件是否填写完毕
  validateCond(
    conditionItem,
    conditions: any[],
    configGroupIndex: number,
    condGroupIndex: number,
    conditionIndex: number
  ): PromiseLike<boolean> {
    if (!conditionItem || !conditions.length) {
      return Promise.resolve(true);
    } else {
      let formName = 'ruleForm_' + configGroupIndex + '-' + condGroupIndex + '-' + conditionIndex;
      return (<any>this.$refs[formName])[0].validate();
    }
  }

  /**
   * 改变选中的表单字段或参数时
   * @param {number} param
   * @returns {number}
   */
  changeConditionField(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    if (!this.treeLoading) {
      return;
    }
    conditionItem.conditions = [];
    conditionItem.key = '';
    conditionItem.compare = '';
    conditionItem.value = null;
    conditionItem.filter = {
      type: '',
      dataPoolType: ''
    };
    if (conditionItem.code.value) {
      let ref = 'condCode_' + configGroupIndex + '-' + condGroupIndex;
      let selectedField = (this.$refs[ref][conditionIndex] as any).getNode(conditionItem.code.value);
      if (selectedField) {
        conditionItem.code.type = selectedField.raw.valueType;
      }
      this.buildWidgetConditions(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
    } else {
      conditions.splice(conditionIndex, 1, conditionItem);
    }
  }

  buildWidgetConditions(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    if (conditionItem.code.type == 'FORM_DATA') {
      // 如果选中的是表单组件
      let selecedWidget: any = this.getSelectedField(conditionItem);
      let curDataSourceConfig = getCurrentDataSourceConfig(selecedWidget);
      if (curDataSourceConfig) {
        // 如果该组件配置了数据源（下拉框等）
        if (curDataSourceConfig.type === WidgetDataSourceConfigTypeEnum.FORM_CONFIG) {
          // 如果该组件的数据源树表单配置
          let params = { code: curDataSourceConfig.formCode };
          getWidgetConditions(params).then((res: any) => {
            if (res.code === '1') {
              conditionItem.conditions = res.data;
              if (conditionItem.key) {
                this.buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
              } else {
                conditionItem.filter = {
                  type: '',
                  dataPoolType: ''
                };
              }
              conditions.splice(conditionIndex, 1, conditionItem);
            }
          });
        } else if (curDataSourceConfig.type === WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
          // 如果该表单配置的是 选项数据源，该组件的可配置条件为本身对应的条件项
          conditionItem.conditions = [];
          conditionItem.key = conditionItem.code.value;
          conditionItem.filter = {
            type: selecedWidget.dataType,
            dataPoolType: 'OPTION_POOL'
          };
          this.buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
        } else {
          // 否则，该组件的可配置条件为本身对应的条件项
          conditionItem.conditions = this._dataSourceStruct[curDataSourceConfig!.type].dataConditions;
          if (conditionItem.key) {
            this.buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
          } else {
            conditionItem.filter = {
              type: '',
              dataPoolType: ''
            };
          }
          conditions.splice(conditionIndex, 1, conditionItem);
        }
      } else {
        // 如果该组件不需要配置数据源（input 或 textarea 等）
        conditionItem.conditions = [];
        conditionItem.filter = {
          type: selecedWidget.dataType,
          dataPoolType: ''
        };
        conditionItem.key = conditionItem.code.value;
        this.buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
        conditions.splice(conditionIndex, 1, conditionItem);
      }
    } else {
      // 如果选中的是表单参数
      let selectedField: any = null;
      this._conditionFields.forEach(item => {
        if (item.valueType == 'FORM_ARGUMENT') {
          item.children.forEach(argument => {
            if (argument.code == conditionItem.code.value) {
              selectedField = argument;
            }
          });
        }
      });
      if (selectedField.type.reality != 'none') {
        conditionItem.conditions = this._widgetRealitysMap[selectedField.type.reality].dataConditions || [];
      } else {
        conditionItem.conditions = [];
        conditionItem.filter = {
          type: selectedField.type.truthType,
          dataPoolType: ''
        };
        conditionItem.key = conditionItem.code.value;
      }
      this.buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
      conditions.splice(conditionIndex, 1, conditionItem);
    }
  }

  /**
   * 根据表单code获取组件
   * @param {number} param
   * @returns {number}
   */

  getSelectedField(conditionItem) {
    let selectedField: any = null;
    if (conditionItem.code.type == 'FORM_ARGUMENT') {
      this._conditionFields.forEach(item => {
        if (item.valueType == 'FORM_ARGUMENT') {
          item.children.forEach(argument => {
            if (argument.code == conditionItem.code.value) {
              selectedField = argument;
            }
          });
        }
      });
    } else {
      selectedField = this._formWidgetMap[conditionItem.code.value];
    }

    return selectedField;
  }

  /*
   *@description: 线条的属性设置时，选择的key变化时，重置运算符和值
   *@author: gongcaixia
   *@date: 2021-04-27 15:28:42
   *@variable1: 行信息
   */

  changeConditionItemKey(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    conditionItem.compare = '';
    conditionItem.value = null;
    let curConditon: any = conditionItem.conditions.find((item: any) => {
      return item.key == conditionItem.key;
    });
    conditionItem.filter = {
      type: curConditon.filter.type,
      dataPoolType: curConditon.filter.dataPoolType
    };
    conditionItem.source = 'SELECT';
    this.buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex);
    conditions.splice(conditionIndex, 1, conditionItem);
  }

  /**
   * 获取每行的运算符
   * @param {Object} conditionItem
   * @returns {Array} operatorList
   */
  getOperatorList(conditionItem) {
    let operatorList: Record<string, any>[] = [];
    if (conditionItem.code.value) {
      if (conditionItem.conditions && conditionItem.conditions.length) {
        let curConditon: any = conditionItem.conditions.find(item => {
          return item.key == conditionItem.key;
        });
        if (curConditon) {
          operatorList = getCompares(curConditon.filter.truthType);
        }
      } else {
        let selecedWidget: any = this.getSelectedField(conditionItem);
        if (conditionItem.code.type == 'FORM_DATA') {
          operatorList = getCompares(selecedWidget.dataType);
        } else {
          operatorList = getCompares(selecedWidget.type.truthType);
        }
      }
    }
    return operatorList;
  }

  // 获取每行条件的值的数据源(非表单字段条件)
  buildStaticConfigDataSource(conditionItem) {
    let dataSource: any[] = [];
    let dataPoolType = '';
    let curConditon: any = conditionItem.conditions.find(item => {
      return item.key == conditionItem.key;
    });
    if (curConditon) {
      dataPoolType = curConditon.filter.dataPoolType || '';
    }
    this._dataSourceMaps.forEach(group => {
      let dataSourcePart = group.data;
      if (group.valueType == 'FIXED_VALUE') {
        dataSourcePart.forEach(item => {
          if (item.type == dataPoolType) {
            if (item.dataValueItems) {
              dataSource = dataSource.concat(item.dataValueItems);
            }
          }
        });
      } else {
        dataSource = dataSource.concat(group.data);
      }
    });
    conditionItem.dataSource = dataSource;
  }

  // 获取每行条件的值的数据源（表单字段的数据源为 表单、意见、选项时）
  buildDynamicConfigDataSource(conditionItem, curDataSourceConfig, conditions, conditionIndex) {
    let dataSource: any[] = [];
    let dataPoolType: any = null;
    if (curDataSourceConfig.type == WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
      dataPoolType = 'OPTION_POOL';
    } else {
      let curConditon: any = conditions.find(item => {
        return item.key == conditionItem.key;
      });
      if (curConditon) {
        dataPoolType = curConditon.filter.dataPoolType || '';
      }
    }
    if (dataPoolType) {
      let formCode = curDataSourceConfig.formCode;
      let processCode = curDataSourceConfig.processCode;
      let dataSourceConfigType = curDataSourceConfig.type;

      let params: any = {
        dataPoolType: dataPoolType,
        param: {
          formModelCode: conditionItem.key,
          dataSourceConfigType: dataSourceConfigType
        }
      };
      if (formCode) {
        params.param.formCode = formCode;
      } else if (processCode) {
        params.param.processCode = processCode;
      }
      if (curDataSourceConfig.type == WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
        params.param.components = this.getSelectedField(conditionItem);
      }
      getBusinessDataSource(params).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          this._dataSourceMaps.forEach(group => {
            let dataSourcePart = res.data;
            if (group.valueType == 'FIXED_VALUE') {
              dataSourcePart.forEach(item => {
                if (item.type == dataPoolType) {
                  if (item.dataValueItems) {
                    dataSource = dataSource.concat(item.dataValueItems);
                  }
                }
              });
            } else {
              dataSource = dataSource.concat(group.data);
            }
          });
        }
        conditionItem.dataSource = dataSource;
        conditions.splice(conditionIndex, 1, conditionItem);
      });
    } else {
      this._dataSourceMaps.forEach(group => {
        if (group.valueType != 'FIXED_VALUE') {
          dataSource = dataSource.concat(group.data);
        }
      });
      conditionItem.dataSource = dataSource;
      conditions.splice(conditionIndex, 1, conditionItem);
    }
  }
  // 获取每行条件的值的数据源
  buildWidgetDataSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    if (conditionItem.code.type == 'FORM_DATA') {
      // 如果选中的是表单组件
      let selecedWidget: any = this.getSelectedField(conditionItem);
      let curDataSourceConfig = getCurrentDataSourceConfig(selecedWidget);
      if (curDataSourceConfig) {
        // 如果该组件配置了数据源（下拉框等）
        if (curDataSourceConfig.formCode || curDataSourceConfig.processCode) {
          this.buildDynamicConfigDataSource(conditionItem, curDataSourceConfig, conditions, conditionIndex);
        } else if (curDataSourceConfig.type === WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
          this.buildDynamicConfigDataSource(conditionItem, curDataSourceConfig, conditions, conditionIndex);
        } else {
          this.buildStaticConfigDataSource(conditionItem);
        }
      } else {
        this.buildStaticConfigDataSource(conditionItem);
      }
    } else if (conditionItem.code.type == 'FORM_ARGUMENT') {
      this.buildStaticConfigDataSource(conditionItem);
    }
  }

  /**
   * 修改条件值类型时
   * @param {number} param
   * @returns {number}
   */
  changeConditionSource(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    conditionItem.result = null;
    // conditions.splice(conditionIndex, 1, conditionItem);
  }

  // 修改条件值时
  changeConditionValue(conditionItem, conditions, configGroupIndex, condGroupIndex, conditionIndex) {
    if (!this.treeLoading) {
      return;
    }
    let value: any[] = [];
    if (conditionItem.result) {
      if (conditionItem.source == 'SELECT') {
        conditionItem.result.forEach(selectVal => {
          let ref = 'condValue_' + configGroupIndex + '-' + condGroupIndex + '-' + conditionIndex;
          let selectedNodeRaw = (this.$refs[ref][0] as any).getNode(selectVal);
          if (selectedNodeRaw) {
            let valueType = selectedNodeRaw.raw.valueType || 'FIXED_VALUE';
            value.push({ type: valueType, value: selectedNodeRaw.raw.code });
          }
        });
      } else {
        value = [{ type: 'FIXED_VALUE', value: conditionItem.result }];
      }
    }
    conditionItem.value = value;
  }

  /**
   * 判断该条件是否是数组
   * @param {number} param
   * @returns {number}
   */
  isCollectorOrTree(conditionItem) {
    return (
      conditionItem.filter &&
      (conditionItem.filter.type == this.widgetDataTypeEnum.TREE || conditionItem.filter.type == this.widgetDataTypeEnum.COLLECTOR)
    );
  }
  /**
   * description  treeselect组件需要id label字段，所以转化数据字段
   * @param {object} node 每一级的数据
   * @returns {object}
   */
  normalizerHandler(node) {
    return {
      id: node.code,
      label: translateText(node.text),
      children: node.children,
      isDisabled: node.disabled == true
    };
  }

  /**
   * description  treeselect组件需要id label字段，所以转化数据字段
   * @param {object} node 每一级的数据
   * @returns {object}
   */
  conditionTreeFormatter(node) {
    return {
      id: node.code,
      label: translateText(node.text),
      children: node.children
    };
  }

  /**
   * 获取配置结果
   * @param {number} param
   * @returns {number}
   */
  getTriggerGroupsResult() {
    let result = SystemUtil.cloneDeep(this.triggerGroupsClone);
    result.map((item, index) => {
      let triggers = (this.$refs.triggerActionsRef[index] as any).getActionsResult();
      if (!triggers || triggers.length == 0) {
        triggers = SystemUtil.cloneDeep(WidgetTriggersData);
      }
      item.triggers = triggers;
      item.conditionGroups = this.removeCondigGroupAuxiliaryProps(item.conditionGroups);
      return item;
    });
    return result;
  }
  removeCondigGroupAuxiliaryProps(conditionGroups) {
    return conditionGroups.map(groupItem => {
      groupItem.conditions = groupItem.conditions.map(conditionItem => {
        delete conditionItem.dataSource;
        delete conditionItem.conditions;
        return conditionItem;
      });
      return groupItem;
    });
  }

  @Watch('triggerGroups')
  triggerGroupsWatcher(val) {
    this.echoConfigData();
  }
}
