























































































import DateUtil from 'global-ui/packages/utils/DateUtil';
import clickoutside from '@/directives/clickoutside';
import { WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import { WidgetDataSourceConfigTypeEnum } from '@/models/form/WidgetDataSourceModel';
import { Vue, Component, Model, InjectReactive } from 'vue-property-decorator';
import { getFormPropTree } from '@/api/form-design/FormApi';
import { getFormConfig, getFormWidgetMap } from '../../utils';

@Component({
  name: 'formual-nested',
  directives: { clickoutside }
})
export default class FormulaNested extends Vue {
  WidgetDataTypeEnum: any = WidgetDataTypeEnum;
  curFactor: any = {
    columnKey: null
  };
  curFactorIndex: number = 0;
  valueTypes: any[] = [];
  factorPropConfigDialogVisible: boolean = false;

  @Model()
  value!: any[];

  @InjectReactive('actived')
  actived: any;

  @InjectReactive()
  formId: any;

  get dragOptions() {
    return {
      animation: 0,
      group: 'formula',
      disabled: false,
      ghostClass: 'ghost'
    };
  }

  get _formWidgetMap() {
    return getFormWidgetMap(this.formId);
  }

  get _dataSourceStruct() {
    return this.$store.getters.dataSourceStruct;
  }

  // 获取reality全局默认映射关系
  get _widgetRealitysMap() {
    return this.$store.getters.widgetRealitysMap;
  }

  /**
   * 判断字段是否已经缺失
   */
  isLostField(factor) {
    if (factor.fieldType == 'FORM_DATA') {
      // 如果是表单组件，获取对应组件
      let widget = this._formWidgetMap[factor.value];
      if (!widget) {
        return true;
      }
    } else if (factor.fieldType == 'FORM_PARAM') {
      // 如果是表单参数. 获取表单参数对象
      let formConfig = getFormConfig(this.formId);
      let curFormArgument = formConfig.container.paramsOptions.find(item => item.name == factor.value);
      if (!curFormArgument) {
        // 如果存在，则必须选择属性
        return true;
      }
    }
    return false;
  }

  /**
   * 判断改字段是否是对象，如果是的话，需要选择对象属性作为公式参数
   */
  isObjectField(factor) {
    if (factor.fieldType == 'FORM_DATA') {
      // 如果是表单组件，获取对应组件
      let widget = this._formWidgetMap[factor.value];
      if (widget) {
        if (widget.groupType == 'option') {
          // 判断groupType是否是 option,如果是，则必须要选择属性（下拉框，选择列表等）
          let dataSourceConfig = widget.dataSource.dataSourceConfig;
          if (dataSourceConfig) {
            return true;
          }
        } else {
          // 如果不是，则返回true（计数器等需要选择）
          return true;
        }
      } else {
        return false;
      }
    } else if (factor.fieldType == 'FORM_PARAM') {
      // 如果是表单参数. 获取表单参数对象
      let formConfig = getFormConfig(this.formId);
      let curFormArgument = formConfig.container.paramsOptions.find(item => item.name == factor.value);
      if (curFormArgument && curFormArgument.type.reality != 'none') {
        // 判断表单参数对象中 reality 是否为 none
        // 如果不是，则必须选择属性
        return true;
      }
    }
    return false;
  }

  /**
   * 选中元素
   */
  selectFactor(factor, list, index) {
    this.$set(factor, 'editable', true);
    this.curFactor = factor;
    if (!this.curFactor.columnKey) {
      this.curFactor.columnKey = null;
    }
    this.curFactorIndex = index;
    this.changeActivedFactor(factor);
  }

  /**
   * 当因子是选项表单项时，配置显示属性
   */
  configFactor(factor) {
    this.buildFactorPropTree(factor);
    this.$nextTick(() => {
      this.factorPropConfigDialogVisible = true;
    });
  }

  /**
   * 修改当前选中的因子
   */
  changeActivedFactor(factor) {
    this.$emit('select', factor);
  }

  /**
   * 删除因子
   */
  deleteFactor(index, factors, evt) {
    if (evt.keyCode == '46') {
      factors.splice(index, 1);
      this.$emit('input', factors);
    }
  }

  buildFactorPropTree(factor) {
    if (factor.fieldType == 'FORM_DATA') {
      // 如果是表单组件，获取对应组件
      let widget = this._formWidgetMap[factor.value];
      // 如果配置了数据源，则需要选择属性，否则不需要
      let dataSourceConfig = widget.dataSource.dataSourceConfig;
      if (dataSourceConfig) {
        if (dataSourceConfig.type == WidgetDataSourceConfigTypeEnum.FORM_CONFIG) {
          // 如果要配置的数据原是表单数据源,获取可选的表单
          this.getFormPropList(dataSourceConfig.formCode);
        } else {
          // 否则从全局数据源配置中获取该数据源结构
          this.valueTypes = this._dataSourceStruct[dataSourceConfig.type].complexColumns;
        }
      } else {
        this.valueTypes = [
          { key: 'DataValue', title: this.$t('lang_form_datasource_module_content_id') },
          { key: 'ModelValue', title: this.$t('lang_name') }
        ];
      }
    } else if (factor.fieldType == 'FORM_PARAM') {
      // 如果是表单参数. 获取表单参数对象
      let formConfig = getFormConfig(this.formId);
      let curFormArgument = formConfig.container.paramsOptions.find(item => item.name == factor.value);
      if (curFormArgument && curFormArgument.type.reality && curFormArgument.type.reality != 'none') {
        // 判断表单参数对象的raality,如果不等于none,则必须选择属性
        this.valueTypes = this._widgetRealitysMap[curFormArgument.type.reality].columns;
      }
    }
  }

  /**
   * 获取选中表单所包含的字段
   * @param {number} param
   * @returns {number}
   */
  getFormPropList(formCode) {
    let params = { code: formCode };
    getFormPropTree(params).then((res: any) => {
      if (res.code === '1') {
        this.valueTypes = this.buildWidgetColumns(res.data);
      }
    });
  }

  /**
   * 讲表单字段构建成 列模式
   */
  buildWidgetColumns(widgets) {
    let result: any[] = [];
    widgets.forEach(widgetItem => {
      let tmp: any = {
        display: false,
        html: false,
        index: 1,
        key: widgetItem.id,
        search: false,
        title: widgetItem.text,
        val: false,
        visible: false
      };
      if (widgetItem.children && widgetItem.children.length) {
        tmp.children = this.buildWidgetColumns(widgetItem.children);
      }
      result.push(tmp);
    });
    return result;
  }

  /**
   * 表单可配置项的格式化
   * @param {number} param
   * @returns {number}
   */
  columnNormalizer(node) {
    return {
      id: node.key,
      label: node.title,
      children: node.children
    };
  }

  changeFactorPropConfig() {
    this.factorPropConfigDialogVisible = false;
  }

  changeFactorList(factorList) {
    this.$emit('input', factorList);
  }

  formatDate(date) {
    return DateUtil.format(new Date(date));
  }

  handleClickOutSide(el) {
    this.$set(el, 'editable', false);
  }
}
