








































































































import { Vue, Component, Prop, Watch, ProvideReactive, InjectReactive } from 'vue-property-decorator';
import FormualNested from './components/formual-nested.vue';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { getCurrentDataSourceConfig, getFormConfig } from '../utils';
import { WidgetDataTypeEnum, LAYOUT_WIDGET_KEYS, WIDGET_TYPE_MAPS } from '@/models/form/WidgetModel';
import { WidgetDataSourceConfigTypeEnum } from '@/models/form/WidgetDataSourceModel';
import i18n from '@/lang';
/**
 * input的配置项
 */
@Component({
  name: 'EleFormulaConfig',
  components: { FormualNested }
})
export default class extends Vue {
  @Prop({ default: () => {} }) value!: Record<string, any>;
  @Prop({ default: i18n.t('lang_configure_default_values') }) placeholder!: string;
  @Prop({ default: 'medium' }) size!: string;
  dialogFlag: boolean = false;
  formula: string = ''; //
  factorTypeVal: string = 'func';
  functionList: any[] = [];
  fieldVal: string = '';
  functionVal: string = '';
  fieldTree: any[] = [];
  otherFactorList: any[] = [
    {
      text: this.$t('lang_input_text'),
      code: 'input'
    },
    {
      text: this.$t('lang_form_components_date'),
      code: 'date'
    }
  ];

  factorList: any[] = [];
  @ProvideReactive('actived')
  currentFactor: any = {
    id: '',
    type: '',
    value: '',
    children: []
  };
  @InjectReactive()
  formId: any;

  get _functions() {
    return this.$store.getters.functions;
  }

  get _dataSourceStruct() {
    return this.$store.getters.dataSourceStruct;
  }

  mounted() {
    this.initData();
  }
  /**
   * description 返显数据源数据
   * @param {}
   * @returns {}
   */
  initData() {
    this.echoConfig();
    this.getFields();
    this.getFunctions();
  }

  echoConfig() {
    if (this.value && this.value.id) {
      this.factorList = [this.value];
      this.$nextTick(() => {
        this.formula = this.buildFormulaStr(this.factorList, '');
      });
    } else {
      this.factorList = [];
      this.formula = '';
    }
  }

  // 获取基于变量和基于参数时的 可用字段
  getFields() {
    this.buildWidgetTree();
  }

  // 组织字段树
  buildWidgetTree() {
    let formConfig = getFormConfig(this.formId);
    let widgetList = formConfig.container.components;
    let widgetTree: any[] = [];
    if (widgetList && widgetList.length) {
      widgetList.forEach((widgetItem, widgetItemIndex) => {
        let childNode: any = {
          parentComponents: widgetItem.parentComponents,
          compType: widgetItem.compType,
          code: widgetItem.compCode,
          text: widgetItem.displayName || this.$t(widgetItem.compName),
          dataType: widgetItem.dataType,
          columnKey: null,
          disabled: false,
          type: 'FORM_DATA'
        };
        this.buildWidgetChildren(widgetItem, childNode);
        widgetTree.push(childNode);
      });
    }
    if (formConfig.container.paramsOptions && formConfig.container.paramsOptions.length) {
      let paramRootNode: any = {
        compType: 'FORM_PARAM',
        code: 'PARAM_ROOT',
        text: this.$t('lang_form_parameters'),
        disabled: true,
        type: 'FORM_PARAM'
      };

      paramRootNode.children = formConfig.container.paramsOptions.map(item => {
        return {
          compType: item.name,
          code: item.name,
          text: item.text,
          disabled: false,
          reality: item.type.reality,
          type: 'FORM_PARAM'
        };
      });
      widgetTree.push(paramRootNode);
    }

    this.fieldTree = widgetTree;
  }

  buildWidgetChildren(widget, treeItem) {
    if ('card' == widget.compType) {
      treeItem.children = [];
      treeItem.disabled = true;
      if (widget.properties.components) {
        widget.properties.components.forEach((cardComponent, cardComponentIndex) => {
          let cardItem = {
            parentComponents: widget,
            compType: cardComponent.compType,
            code: cardComponent.compCode,
            dataType: cardComponent.dataType,
            columnKey: null,
            text: cardComponent.displayName || this.$t(cardComponent.compName),
            disabled: false,
            type: 'FORM_DATA'
          };
          this.buildWidgetChildren(cardComponent, cardItem);
          treeItem.children.push(cardItem);
        });
      }
    } else if ('row' == widget.compType) {
      treeItem.children = [];
      treeItem.disabled = true;
      widget.properties.gridColumns.forEach(gridColumn => {
        if (gridColumn.components) {
          gridColumn.components.forEach((gridCellWidget, gridCellWidgetIndex) => {
            let gridItem = {
              parentComponents: widget,
              compType: gridCellWidget.compType,
              code: gridCellWidget.compCode,
              dataType: gridCellWidget.dataType,
              columnKey: null,
              text: gridCellWidget.displayName || this.$t(gridCellWidget.compName),
              disabled: false,
              type: 'FORM_DATA'
            };
            treeItem.children.push(gridItem);
          });
        }
      });
    } else if ('table' == widget.compType) {
      treeItem.children = [];
      treeItem.disabled = true;
      if (widget.properties.components) {
        widget.properties.components.forEach((tableCellWidget, tableCellWidgetIndex) => {
          let tableItem = {
            parentComponents: widget,
            compType: tableCellWidget.compType,
            code: tableCellWidget.compCode,
            dataType: tableCellWidget.dataType,
            columnKey: null,
            text: tableCellWidget.displayName || this.$t(tableCellWidget.compName),
            disabled: false,
            type: 'FORM_DATA'
          };
          treeItem.children.push(tableItem);
        });
      }
    } else if ('dialogList' == widget.compType) {
      treeItem.children = [];
      treeItem.disabled = true;
      let currentDataSourceConfig = getCurrentDataSourceConfig(widget);
      if (currentDataSourceConfig) {
        if (currentDataSourceConfig.type == WidgetDataSourceConfigTypeEnum.FORM_CONFIG) {
          let customComplexColumns = currentDataSourceConfig.columnConfig.customComplexColumns;
          customComplexColumns.forEach((item: any) => {
            let structItem = {
              parentComponents: widget,
              compType: '_dialog-list-column',
              code: item.key,
              text: item.title,
              disabled: false,
              type: 'FORM_DATA'
            };
            treeItem.children.push(structItem);
          });
        } else {
          let dataSourceStruct = this._dataSourceStruct[currentDataSourceConfig.type].complexColumns;
          dataSourceStruct.forEach(item => {
            let structItem = {
              parentComponents: widget,
              compType: '_dialog-list-column',
              code: item.key,
              text: item.title,
              disabled: false,
              type: 'FORM_DATA'
            };
            treeItem.children.push(structItem);
          });
        }
      }
    }
  }

  // 获取基于变量和基于参数时的 可用函数
  getFunctions() {
    this.functionList = this._functions || [];
  }

  // 打开表达式配置框
  openVariableConfigDialog() {
    this.dialogFlag = true;
  }

  // 为公式添加函数
  addFunction({ row }) {
    let factor = {
      id: SystemUtil.uuid(),
      type: 'function',
      value: row.type,
      label: row.type,
      children: []
    };
    if (!this.factorList || this.factorList.length === 0) {
      this.factorList.push(factor);
      this.currentFactor = factor;
    } else if (this.currentFactor.id) {
      if (this.currentFactor.type == 'function') {
        this.currentFactor.children.push(factor);
      } else {
        this.$message.warning(this.$t('lang_Illegal_operation') as any);
      }
    } else {
      let rootFactor = this.factorList[0];
      rootFactor.children.push(factor);
      this.factorList = [rootFactor];
    }
  }

  canSelectFunction({ row }) {
    if (this.factorList.length === 0) {
      // 第一次可以选择任何公式元素
      return true;
    } else if (!this.currentFactor.id) {
      return false;
    } else if (this.currentFactor.type == 'function') {
      // 如果不是第一次添加元素，而当前选中的元素为公式
      return true;
    } else {
      return false;
    }
  }

  // 字段表格中某一行是否可选中
  canSelectField({ row }) {
    return this.canAddField(row);
  }

  // 为公式添加字段
  addField({ row }) {
    if (!this.canAddField(row)) {
      return false;
    }
    let factor: any = {
      id: SystemUtil.uuid(),
      type: 'field',
      fieldType: row.type,
      value: row.code,
      label: row.text,
      dataType: row.dataType,
      editable: true,
      children: []
    };
    if (this.isGroupField(row)) {
      let parentCompCode = row.code;
      if (row.parentComponents) {
        parentCompCode = row.parentComponents.compCode;
      }
      factor.groupColumnName = parentCompCode;
    }
    if (row.dataType == WidgetDataTypeEnum.COLLECTOR || row.dataType == WidgetDataTypeEnum.TREE) {
      factor.columnKey = row.columnKey;
    }
    if (!this.factorList || this.factorList.length === 0) {
      this.factorList.push(factor);
      this.currentFactor = factor;
    } else if (this.currentFactor.id) {
      if (this.currentFactor.type == 'function') {
        this.currentFactor.children.push(factor);
      } else {
        this.$message.warning(this.$t('lang_Illegal_operation') as any);
      }
    } else {
      let rootFactor = this.factorList[0];
      rootFactor.children.push(factor);
      this.factorList = [rootFactor];
    }
  }
  // 是否可以添加字段
  canAddField(field: any) {
    if (field.disabled) {
      // 如果是布局组件或者选择列表，则不可选
      return false;
    } else if (this.isLayout(field) || field.compType == WIDGET_TYPE_MAPS.DIALOG_LIST) {
      // 如果是布局组件或者选择列表，则不可选
      return false;
    } else if (this.factorList.length === 0) {
      // 第一次可以选择任何公式元素
      return true;
    } else if (!this.currentFactor.id) {
      return false;
    } else if (this.currentFactor.type == 'function') {
      // 如果不是第一次添加元素，而当前选中的元素为公式
      let curFunction = this.functionList.find(item => item.type == this.currentFactor.value);
      if (curFunction.group) {
        if (this.isGroupField(field)) {
          // 当前选中的元素为公式，并且选择的字段属于分组字段（表格、选择列表等中的字段），则可添加
          return true;
        } else {
          // 当前选中的元素为公式，但是选择的字段不属于分组字段，则不可添加
          return false;
        }
      }
      return true;
    } else {
      // 如果不是第一次添加元素，且当前选中的元素不是公式，则不可添加元素
      return false;
    }
  }

  // 是否是布局组件
  isLayout(field: any) {
    return LAYOUT_WIDGET_KEYS.includes(field.compType);
  }

  // 是否是可聚合的字段
  isGroupField(selectWidget) {
    if (selectWidget.parentComponents) {
      return selectWidget.parentComponents.compType == 'table' || selectWidget.parentComponents.compType == 'dialogList';
    }
    return false;
  }

  // 为公式添加常量
  addConstant({ row }) {
    let factor = {
      id: SystemUtil.uuid(),
      type: 'constants',
      value: '',
      label: row.code,
      editable: true,
      children: []
    };
    if (!this.factorList || this.factorList.length === 0) {
      this.factorList.push(factor);
      this.currentFactor = factor;
    } else if (this.currentFactor.id) {
      if (this.currentFactor.type == 'function') {
        this.currentFactor.children.push(factor);
      } else {
        this.$message.warning(this.$t('lang_Illegal_operation') as any);
      }
    } else {
      let rootFactor = this.factorList[0];
      rootFactor.children.push(factor);
      this.factorList = [rootFactor];
    }
  }
  // 是否可以添加常量
  canSelectConstant({ row }) {
    if (this.factorList.length === 0) {
      // 第一次可以选择任何公式元素
      return true;
    } else if (!this.currentFactor.id) {
      return false;
    } else if (this.currentFactor.type == 'function') {
      // 如果不是第一次添加元素，而当前选中的元素为公式
      return true;
    } else {
      return false;
    }
  }

  /**
   * 修改选中的表达式因素时
   */
  changeActivedFactor(factor) {
    this.currentFactor = factor;
  }

  // 保存表达式
  saveFormula() {
    if (this.factorList && this.factorList.length) {
      this.formula = this.buildFormulaStr(this.factorList, '');
      this.$emit('change', this.factorList[0], this.formula);
    } else {
      this.$emit('change', null);
      this.formula = '';
    }
    this.dialogFlag = false;
  }

  buildFormulaStr(factorList, result: string = '') {
    factorList.forEach((item, index) => {
      if (item.type == 'function') {
        result = result + item.value + '(';
        if (item.children && item.children.length) {
          result = this.buildFormulaStr(item.children, result);
        }
        result += ')';
      } else if (item.type == 'field') {
        result += item.label + ',';
      } else if (item.type == 'constants') {
        result += item.value + ',';
      }
      if (index == factorList.length - 1 && item.type != 'function') {
        result = result.substring(0, result.length - 1);
      }
    });
    return result;
  }

  @Watch('value')
  valueWatcher(val) {
    this.echoConfig();
  }
}
