import { Component, Vue, Watch } from 'vue-property-decorator';
import DictConstant from '@/const/DictConstant';

@Component({
  name: 'Dict'
})
export default class extends Vue {
  get mixins_dict() {
    return DictConstant;
  }
}
