var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "condition-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _vm._l(_vm.conditionGroups, function (groupItem, groupIndex) {
            return _c(
              "div",
              {
                key: groupIndex,
                staticClass: "condition-wrapper",
                attrs: { name: groupItem.key },
              },
              [
                _c("div", { staticClass: "condtion-group-top" }, [
                  _c(
                    "div",
                    { staticClass: "condtion-group-top-left" },
                    [
                      _c("span", { staticClass: "condtion-group-title" }, [
                        _vm._v(
                          _vm._s(_vm.$t("lang_rule_group1")) +
                            _vm._s(groupIndex + 1)
                        ),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80px" },
                          attrs: {
                            size: "small",
                            placeholder: "逻辑关系",
                            disabled: groupIndex == 0,
                          },
                          model: {
                            value: groupItem.logical,
                            callback: function ($$v) {
                              _vm.$set(groupItem, "logical", $$v)
                            },
                            expression: "groupItem.logical",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              value: _vm.mixins_dict.SWITCH_COND_LOGICAL.AND,
                              label: _vm.$t("lang_also"),
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              value: _vm.mixins_dict.SWITCH_COND_LOGICAL.OR,
                              label: _vm.$t("lang_or"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "condtion-group-top-right" },
                    [
                      _c("el-button", {
                        staticStyle: { "margin-bottom": "3px" },
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          circle: "",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addGroup(
                              _vm.conditionGroups,
                              groupItem,
                              groupIndex
                            )
                          },
                        },
                      }),
                      _c("el-button", {
                        staticStyle: {
                          "margin-left": "5px",
                          "margin-bottom": "3px",
                        },
                        attrs: {
                          type: "primary",
                          icon: "el-icon-delete",
                          circle: "",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.removeGroup(
                              _vm.conditionGroups,
                              groupIndex
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "condtion-group-body" },
                  _vm._l(
                    groupItem.conditions,
                    function (conditionItem, conditionIndex) {
                      return _c(
                        "el-form",
                        {
                          key: groupIndex + "-" + conditionIndex,
                          ref: "ruleForm_" + groupIndex,
                          refInFor: true,
                          staticClass: "condtion-form",
                          attrs: {
                            model: conditionItem,
                            rules: _vm.commonRules,
                            "label-width": "0px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "condtion-form-item",
                              attrs: { label: "", prop: "logical" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "逻辑关系",
                                    disabled: conditionIndex == 0,
                                  },
                                  model: {
                                    value: conditionItem.logical,
                                    callback: function ($$v) {
                                      _vm.$set(conditionItem, "logical", $$v)
                                    },
                                    expression: "conditionItem.logical",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value:
                                        _vm.mixins_dict.SWITCH_COND_LOGICAL.AND,
                                      label: _vm.$t("lang_also"),
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value:
                                        _vm.mixins_dict.SWITCH_COND_LOGICAL.OR,
                                      label: _vm.$t("lang_or"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "condtion-form-item",
                              attrs: { label: "", prop: "key" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: _vm.$t("lang_please_select"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeConditionItemKey(
                                        conditionItem,
                                        groupItem.conditions,
                                        conditionIndex
                                      )
                                    },
                                  },
                                  model: {
                                    value: conditionItem.key,
                                    callback: function ($$v) {
                                      _vm.$set(conditionItem, "key", $$v)
                                    },
                                    expression: "conditionItem.key",
                                  },
                                },
                                _vm._l(_vm.conditions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: {
                                      value: item.key,
                                      label: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "condtion-form-item",
                              attrs: { label: "", prop: "compare" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: _vm.$t("lang_operator"),
                                  },
                                  model: {
                                    value: conditionItem.compare,
                                    callback: function ($$v) {
                                      _vm.$set(conditionItem, "compare", $$v)
                                    },
                                    expression: "conditionItem.compare",
                                  },
                                },
                                _vm._l(
                                  _vm.getOperatorList(conditionItem),
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: item.type,
                                        label: item.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "condtion-form-item",
                              attrs: { label: "", prop: "source" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled:
                                      !conditionItem.key ||
                                      conditionItem.filter.type ==
                                        _vm.widgetDataTypeEnum.TREE ||
                                      conditionItem.filter.type ==
                                        _vm.widgetDataTypeEnum.COLLECTOR,
                                    size: "small",
                                    placeholder: _vm.$t("lang_value_type"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeConditionValueSource(
                                        conditionItem,
                                        groupItem.conditions,
                                        conditionIndex
                                      )
                                    },
                                  },
                                  model: {
                                    value: conditionItem.source,
                                    callback: function ($$v) {
                                      _vm.$set(conditionItem, "source", $$v)
                                    },
                                    expression: "conditionItem.source",
                                  },
                                },
                                _vm._l(_vm.valueTypes, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      value: item.id,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          [
                            _vm.isCollectorOrTree(conditionItem) ||
                            conditionItem.source == "SELECT"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "condtion-form-item",
                                    attrs: {
                                      label: "",
                                      prop: "result",
                                      rules: _vm.isCollectorOrTree(
                                        conditionItem
                                      )
                                        ? _vm.arrayRules
                                        : _vm.singleRules,
                                    },
                                  },
                                  [
                                    conditionItem.dataSource
                                      ? _c("treeselect", {
                                          ref:
                                            "condValue_" +
                                            groupIndex +
                                            "_" +
                                            conditionIndex,
                                          refInFor: true,
                                          staticClass: "cus-treeselect",
                                          attrs: {
                                            normalizer: _vm.normalizerHandler,
                                            multiple:
                                              _vm.isCollectorOrTree(
                                                conditionItem
                                              ),
                                            limit: 1,
                                            flat: "",
                                            appendToBody: "",
                                            zIndex: "9999",
                                            "value-consists-of": "ALL",
                                            options: conditionItem.dataSource,
                                            noOptionsText:
                                              _vm.$t("lang_no_data"),
                                            noResultsText: _vm.$t(
                                              "lang_no_search_results"
                                            ),
                                            placeholder:
                                              _vm.$t("lang_select_value"),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.changeConditionValue(
                                                conditionItem,
                                                groupIndex,
                                                conditionIndex
                                              )
                                            },
                                          },
                                          model: {
                                            value: conditionItem.result,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                conditionItem,
                                                "result",
                                                $$v
                                              )
                                            },
                                            expression: "conditionItem.result",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : conditionItem.filter &&
                                conditionItem.filter.type == "NUMBER"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "condtion-form-item",
                                    attrs: {
                                      label: "",
                                      prop: "result",
                                      rules: _vm.numberRules,
                                    },
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        size: "small",
                                        placeholder: _vm.$t(
                                          "lang_autu_end_condition_value"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeConditionValue(
                                            conditionItem,
                                            groupIndex,
                                            conditionIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value: conditionItem.result,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            conditionItem,
                                            "result",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "conditionItem.result",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : conditionItem.filter &&
                                conditionItem.filter.type == "DATE"
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticClass: "condtion-form-item",
                                    attrs: {
                                      label: "",
                                      prop: "result",
                                      rules: _vm.dateRules,
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      attrs: {
                                        size: "small",
                                        clearable: true,
                                        placeholder:
                                          _vm.$t("lang_please_select"),
                                        format: "yyyy-MM-dd",
                                        type: "date",
                                        "value-format": "timestamp",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeConditionValue(
                                            conditionItem,
                                            groupIndex,
                                            conditionIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value: conditionItem.result,
                                        callback: function ($$v) {
                                          _vm.$set(conditionItem, "result", $$v)
                                        },
                                        expression: "conditionItem.result",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-form-item",
                                  {
                                    staticClass: "condtion-form-item",
                                    attrs: {
                                      label: "",
                                      prop: "result",
                                      rules: _vm.stringRules,
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        placeholder: _vm.$t(
                                          "lang_autu_end_condition_value"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeConditionValue(
                                            conditionItem,
                                            groupIndex,
                                            conditionIndex
                                          )
                                        },
                                      },
                                      model: {
                                        value: conditionItem.result,
                                        callback: function ($$v) {
                                          _vm.$set(conditionItem, "result", $$v)
                                        },
                                        expression: "conditionItem.result",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ],
                          _c(
                            "div",
                            { staticClass: "condtion-form-item" },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-plus",
                                  circle: "",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.addCondition(
                                      groupItem.conditions,
                                      conditionIndex,
                                      "ruleForm_" + groupIndex
                                    )
                                  },
                                },
                              }),
                              _c("el-button", {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-delete",
                                  circle: "",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeCondition(
                                      groupItem.conditions,
                                      conditionIndex
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    }
                  ),
                  1
                ),
              ]
            )
          }),
          _vm.conditionGroups && _vm.conditionGroups.length == 0
            ? _c(
                "div",
                {
                  staticClass: "add-btn-wrapper",
                  on: {
                    click: function ($event) {
                      return _vm.addGroup(_vm.conditionGroups)
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }