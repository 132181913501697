



























































































































































































































































































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { getOrgAndUserByIds } from '@/api/system/UserApi';
import {
  WidgetDataSourceColumnData,
  WidgetDataSourceConfigData,
  WidgetDataSourceConfigModel,
  WidgetDataSourceConfigTypeEnum
} from '@/models/form/WidgetDataSourceModel';
import {
  getPublishFormTree,
  getFormPropTree,
  getWidgetConditions,
  getPublishProcessTree
} from '@/api/form-design/FormApi';
import AppApi from '@/api/application/AppApi';
import { WidgetDataSourceTypeEnum } from '@/models/form/WidgetDataSourceModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetData, WidgetDataTypeEnum, WidgetModel } from '@/models/form/WidgetModel';
import EleDataSourceConfigItem from './ele-data-source-config-item.vue';
import EleDataSourceValueConfigItem from './ele-data-source-config-value-item.vue';
import EleDataSourceOrderConfig from './ele-data-source-order-config.vue';

import BindScope from '@/components/bind-scope/index.vue';
interface paramsList {
  applicationId: string;
}
/**
 * input的配置项
 */
@Component({
  name: 'EleDataSourceConfigForm',
  components: {
    EleDataSourceConfigItem,
    EleDataSourceOrderConfig,
    EleDataSourceValueConfigItem,
    BindScope
  }
})
export default class EleDataSourceConfigForm extends Vue {
  @Prop({
    default: () => {
      return SystemUtil.cloneDeep(WidgetDataSourceConfigData);
    }
  })
  dataSourceConfig!: WidgetDataSourceConfigModel;
  @Prop({
    default: () => {
      return SystemUtil.cloneDeep(WidgetData);
    }
  })
  widget!: WidgetModel;

  activeTab: string = 'column';

  widgetDataSourceTypeEnum: any = WidgetDataSourceTypeEnum;
  widgetDataSourceConfigTypeEnum: any = WidgetDataSourceConfigTypeEnum;
  widgetDataTypeEnum: any = WidgetDataTypeEnum;
  // 数据源配置
  dataSourceConfigClone: WidgetDataSourceConfigModel = SystemUtil.cloneDeep(
    WidgetDataSourceConfigData
  );
  // 如果数据源是人员，并且是tree时
  dataLevels: any[] = [
    { id: 'dept_role_user', label: '部门角色人员' },
    { id: 'dept_user', label: '部门人员' },
    { id: 'role_dept_user', label: '角色部门人员' },
    { id: 'role_user', label: '角色人员' }
  ];
  // 表单列表
  formList: any[] = [];

  // 流程列表，用于数据源类型为 意见时
  processList: any[] = [];

  // 选中的表单字段列表
  formWidgetList: any[] = [];

  // 选中的表单字段列表
  formFieldList: any[] = [];

  conditions: any[] = [];
  rule: Record<string, any> = {
    formCode: [
      {
        required: true,
        validator: this.validFormCode,
        trigger: 'change'
      }
    ],
    dataLevel: [
      {
        required: true,
        trigger: 'change',
        message: '请选择人员数据结构'
      }
    ],
    columnConfig: {
      customSimpleColumn: {
        required: true,
        validator: this.validKey,
        trigger: 'change'
      }
    },
    processCode: {
      required: true,
      message: '请选择流程名称',
      trigger: 'blur'
    }
  };

  selectedScopeList: any = [];
  // 获取数据源结构
  get _dataSourceStruct() {
    return this.$store.getters.dataSourceStruct;
  }

  mounted() {
    this.initData();
  }
  setSelectedScopeList(val) {
    this.selectedScopeList = val;
  }

  validFormCode(f, value, cb) {
    if (this.dataSourceConfigClone.formCode) {
      cb();
    } else {
      cb(new Error('请选择表单名称'));
    }
  }
  validKey(f, value, cb) {
    if (this.dataSourceConfigClone.columnConfig.customSimpleColumn.key) {
      cb();
    } else {
      cb(new Error('请选择显示项'));
    }
  }
  /**
   * 返回Promise,返回校验结果
   *
   */
  validateConfig(): PromiseLike<boolean> {
    return new Promise((resolve, reject) => {
      let flag = true;
      (this.$refs.configForm as any).validate((v) => {
        if (v) {
          if (
            this.dataSourceConfigClone.type ==
            this.widgetDataSourceConfigTypeEnum.OPTION_CONFIG
          ) {
            if (
              !this.dataSourceConfigClone.normalDataList ||
              !this.dataSourceConfigClone.normalDataList.length
            ) {
              this.activeTab = 'option';
              flag = false;
            } else {
              flag = true;
            }
          } else {
            (this.$refs.conditionConfigRef as any)
              .validateAll()
              .then((v2) => {
                if (!v2) {
                  this.activeTab = 'condition';
                }
                flag = true;
              })
              .catch((e) => {
                this.activeTab = 'condition';
                flag = false;
              });
          }
        } else {
          this.activeTab = 'column';
          flag = false;
        }
      });
      if (!(this.$refs.orderConfigRef as any).validateConfig()) {
        this.activeTab = 'order';
        flag = false;
      }
      resolve(flag);
    });
  }
  /**
   * description 返显数据源数据
   * @param {}
   * @returns {}
   */
  initData() {
    this.getFormList();
    if (
      this.dataSourceConfig &&
      this.dataSourceConfig.type == WidgetDataSourceConfigTypeEnum.FORM_CONFIG
    ) {
      // 如果要配置的数据原是表单数据源,获取可选的表单
      this.dataSourceConfigClone = SystemUtil.cloneDeep(this.dataSourceConfig);
    } else {
      if (
        this.dataSourceConfig &&
        this.dataSourceConfig.type ==
          WidgetDataSourceConfigTypeEnum.PROCESS_TASK_LOG_CONFIG
      ) {
        // 如果要配置的数据原是表单数据源,获取可选的流程树
        this.getProcessList();
      }
      this.formWidgetList = this._dataSourceStruct[
        this.dataSourceConfig.type
      ].complexColumns;
      let dataSourceConfig = SystemUtil.cloneDeep(this.dataSourceConfig);
      if (this.widget.dataSource.type == this.widgetDataSourceTypeEnum.COMPLEX) {
        if (
          !dataSourceConfig.columnConfig.customComplexColumns ||
          dataSourceConfig.columnConfig.customComplexColumns.length == 0
        ) {
          dataSourceConfig.columnConfig.customComplexColumns = [
            ...this._dataSourceStruct[this.dataSourceConfig.type].complexColumns
          ];
        }
      } else {
        if (
          !dataSourceConfig.columnConfig.customSimpleColumn ||
          !dataSourceConfig.columnConfig.customSimpleColumn.key
        ) {
          dataSourceConfig.columnConfig.customSimpleColumn = {
            ...this._dataSourceStruct[this.dataSourceConfig.type].simpleColumn
          };
        }
      }
      // zaa 新增
      if (this._dataSourceStruct[this.dataSourceConfig.type].conditionValueConfigs) {
        dataSourceConfig.conditionValueConfigs = this._dataSourceStruct[
          this.dataSourceConfig.type
        ].conditionValueConfigs;
      }

      // 回显数据 selectedScopeList
      if (dataSourceConfig.scope) {
        this.setSelectedScopeListFn(dataSourceConfig.scope, false);
      }

      this.conditions = this.getConditions();
      this.dataSourceConfigClone = SystemUtil.cloneDeep(dataSourceConfig);
    }
  }
  // 将数据源结构转为显示项数据格式
  buildFormWidget(formFields) {
    return formFields.map((item) => {
      return {
        code: item.key,
        text: item.title
      };
    });
  }

  /**
   * 获取组件对应的条件列表
   * @param {number} param
   * @returns {number}
   */
  getConditions() {
    let curDataSourceStruct = this._dataSourceStruct[this.dataSourceConfig.type];
    if (curDataSourceStruct) {
      return curDataSourceStruct.dataConditions;
    }
    return [];
  }

  /**
   * 获取可选择的表单列表
   * @param {number} param
   * @returns {number}
   */
  getFormList() {
    getPublishFormTree().then((res: any) => {
      if (res.code === '1') {
        this.formList = res.data;
        if (this.dataSourceConfig.formCode) {
          this.$nextTick(() => {
            this.handleChangeForm(this.dataSourceConfig.formCode as string);
          });
        }
      }
    });
  }

  /**
   * 改变选择的表单时
   * @param {number} param
   * @returns {number}
   */
  handleChangeForm(val: string) {
    if (this.formList && this.formList.length) {
      this.getFormPropList(val);
      this.getFormFieldWidthConditions(val);
    }
  }
  // 只改变条件值 不改变条件
  handleChangeFormVal(val: string) {
    if (this.formList && this.formList.length) {
      this.getFormPropList(val);
    }
  }

  /**
   * 表单树格式器
   * @param {number} param
   * @returns {number}
   */
  formNormalizer(node) {
    return {
      id: node.code,
      label: node.displayName || this.$t(node.text),
      children: node.children
    };
  }

  /**
   * 获取选中表单所包含的字段
   * @param {number} param
   * @returns {number}
   */
  getFormPropList(formCode: string) {
    let params = { code: formCode };
    getFormPropTree(params).then((res: any) => {
      if (res.code === '1') {
        this.formWidgetList = this.buildWidgetColumns(res.data);
      }
    });
  }

  /**
   * 讲表单字段构建成 列模式
   */
  buildWidgetColumns(widgets) {
    let result: any[] = [];
    widgets.forEach((widgetItem) => {
      let tmp: any = {
        display: false,
        html: false,
        index: 1,
        key: widgetItem.id,
        search: false,
        title: widgetItem.displayName || widgetItem.text,
        val: false,
        visible: false
      };
      if (widgetItem.children && widgetItem.children.length) {
        tmp.children = this.buildWidgetColumns(widgetItem.children);
      }
      result.push(tmp);
    });
    return result;
  }

  /**
   * 获取选中表单所包含的字段
   * @param {number} param
   * @returns {number}
   */
  getFormFieldWidthConditions(formCode: string) {
    let params = { code: formCode };
    getWidgetConditions(params).then((res: any) => {
      if (res.code === '1') {
        this.conditions = res.data;
      }
    });
  }

  /**
   * 获取流程数据
   */
  getProcessList() {
    let applicationId = sessionStorage.getItem('applicationId');
    let params = {
      linkType: 2,
      applicationId: applicationId
    };
    AppApi.getApplicationLinkDataList(params).then((res: any) => {
      // getPublishProcessTree().then((res: any) => {
      if (res.code === '1') {
        this.processList = res.data;
        if (this.dataSourceConfig.processCode) {
          this.$nextTick(() => {
            // this.handleChangeForm(this.dataSourceConfig.formCode as string);
          });
        }
      }
    });
  }

  /**
   * 表单可配置项的格式化
   * @param {number} param
   * @returns {number}
   */
  columnNormalizer(node) {
    return {
      id: node.key,
      label: node.displayName || this.$t(node.title),
      children: node.children
    };
  }

  // 添加条件
  addPropConfig(curCondition) {
    if (this.validatePropConfig(curCondition)) {
      let condition = SystemUtil.cloneDeep(WidgetDataSourceColumnData);
      this.dataSourceConfigClone.columnConfig.customComplexColumns.push(condition);
    }
  }

  changeProp(node, propItem, props, index) {
    if (propItem && propItem.key) {
      props.splice(index, 1, propItem);
    }
  }

  /**
   * 校验上一个条件项是否正确
   * @param {number} param
   * @returns {number}
   */
  validatePropConfig(curCondition) {
    return true;
  }
  // 移除条件
  removePropConfig(index) {
    this.dataSourceConfigClone.columnConfig.customComplexColumns.splice(index, 1);
  }

  /**
   * 添加选项
   */
  addOption() {
    let tmp = {};
    this.formWidgetList.forEach((item) => {
      tmp[item.key] = '';
    });
    this.dataSourceConfigClone.normalDataList.push(tmp);
  }

  // 处理属性
  setSelectedScopeListFn(val, falg: boolean = true, id?) {
    // falg 处理数据
    if (falg) {
      let scope = {
        groupIds: [],
        userIds: []
      };
      val.map((item) => {
        if (item.type == 'org') {
          scope.groupIds.push(item.id);
        }
        if (item.type == 'user') {
          scope.userIds.push(item.id);
        }
        return scope;
      });
    } else {
      let selectedScopeList = [];
      getOrgAndUserByIds(val).then((res: Record<any, any>) => {
        if (res.code == '1') {
          let groupList = res.data.groups;
          let userList = res.data.users;
          if (groupList) {
            groupList.forEach((item) => {
              let row = {
                id: item.id,
                type: 'org',
                name: item.name
              };
              selectedScopeList.push(row);
            });
          }
          if (userList) {
            userList.forEach((item) => {
              let row = {
                id: item.id,
                type: 'user',
                name: item.name
              };
              selectedScopeList.push(row);
            });
          }
          this.selectedScopeList = selectedScopeList;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
  /**
   * 父级页面通过ref调用,获取最终的配偶结果
   */
  getDataSourceConfig() {
    if (
      this.dataSourceConfigClone.type != this.widgetDataSourceConfigTypeEnum.OPTION_CONFIG
    ) {
      this.dataSourceConfigClone.conditionConfig.dataListConditions = (this.$refs
        .conditionConfigRef as any).getDataListConditions();
    }
    this.dataSourceConfigClone.columnConfig.orderConfigs = (this.$refs
      .orderConfigRef as any).getOrderConfigs();

    if (this.dataSourceConfigClone.scopeType == 2) {
      this.dataSourceConfigClone.scope = this.setSelectedScopeListFn(
        this.selectedScopeList,
        true
      );
    }
    return SystemUtil.cloneDeep(this.dataSourceConfigClone);
  }
}
