


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EleDataSourceConfigItem from './ele-data-source-config-item.vue';
import EleDataSourceConfigForm from './ele-data-source-config-form.vue';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
import {
  WidgetDataSourceConfigData,
  WidgetDataSourceConfigModel,
  WidgetDataSourceConfigTypeEnum
} from '@/models/form/WidgetDataSourceModel';
@Component({
  name: 'DataSourceConfigDialog',
  components: {
    EleDataSourceConfigItem,
    EleDataSourceConfigForm
  }
})
export default class extends Vue {
  @Prop({
    default: () => {
      return WidgetData;
    }
  })
  widget!: WidgetModel;

  @Prop({
    default: false
  })
  visible!: boolean;

  widgetClone: WidgetModel = SystemUtil.cloneDeep(WidgetData);
  showDataSourceDialog: boolean = false;
  currentDataSourceConfig: WidgetDataSourceConfigModel = WidgetDataSourceConfigData;
  widgetDataSourceConfigTypeEnum: any = WidgetDataSourceConfigTypeEnum;
  created() {
    this.widgetClone = SystemUtil.cloneDeep(this.widget);
  }

  /*
   *@description: 点击数据源下每一项弹出配置数据源弹框
   *@author: gongcaixia
   *@date: 2021-05-13 15:29:05
   *@item: 点击当前项的信息
   */
  showDataSourceConfigDialog() {
    this.buildDefaultDataSourceConfig(this.widgetClone.dataSource.dataSourceConfig);
    this.$nextTick(() => {
      this.showDataSourceDialog = true;
    });
  }

  /*
   *@description: 添加 defaultDataConditions dataListConditions字段
   *@author: gongcaixia
   *@date: 2021-05-17 11:45:12
   */
  buildDefaultDataSourceConfig(configItem): any {
    let configItemClone = SystemUtil.cloneDeep(configItem);
    let dataSourceConfig = SystemUtil.cloneDeep(WidgetDataSourceConfigData);
    dataSourceConfig = Object.assign(dataSourceConfig, configItemClone);
    if (configItem.type != this.widgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
      if (configItemClone.conditionConfig.dataListConditions && configItemClone.conditionConfig.dataListConditions.length) {
        dataSourceConfig.conditionConfig.dataListConditions = configItemClone.conditionConfig.dataListConditions.map(groupItem => {
          groupItem.conditions.map(conditionItem => {
            conditionItem.dataSource = [];
            return conditionItem;
          });
          return groupItem;
        });
      } else {
        dataSourceConfig.conditionConfig.dataListConditions = [];
      }
    }
    this.currentDataSourceConfig = SystemUtil.cloneDeep(dataSourceConfig);
  }

  /**
   * description  静态数据时 类型为widgetDataSourceConfigTypeEnum.OPTION_CONFIG时，添加选项
   * @param {}
   * @returns {}
   */
  addOption() {
    this.currentDataSourceConfig.normalDataList.push({
      text: '',
      code: ''
    });
  }

  /**
   * description  配置数据源弹框 确定
   * @param {}
   * @returns {}
   */
  async saveDataSourceConfig() {
    let elementClone = SystemUtil.cloneDeep(this.widgetClone);
    let validateResult = await (this.$refs.dataSourceConfigFormRef as any).validateConfig();
    if (!validateResult) {
      this.$message.warning(this.$t('lang_check_datasource') as any);
      return;
    }
    let dataSourceConfig = (this.$refs.dataSourceConfigFormRef as any).getDataSourceConfig();
    // 删除数据源条件中的辅助项-dataSource
    if (dataSourceConfig.conditionConfig.dataListConditions) {
      dataSourceConfig.conditionConfig.dataListConditions = dataSourceConfig.conditionConfig.dataListConditions.map((groupItem: any) => {
        groupItem.conditions.map(conditionItem => {
          delete conditionItem.dataSource;
          return conditionItem;
        });
        return groupItem;
      });
    }
    this.currentDataSourceConfig = dataSourceConfig;
    // 替换组件对应的数据源信息
    elementClone.dataSource.dataSourceConfig = this.currentDataSourceConfig;

    this.widgetClone = SystemUtil.cloneDeep(elementClone);
    // 向上emit

    this.$emit('save', elementClone);
    this.$emit('update:visible', false);
    this.showDataSourceDialog = false;
  }

  closeDataSourceConfigDialog() {
    this.$emit('update:visible', false);
    this.showDataSourceDialog = false;
  }

  // 构建保存时的数据：点击保存时 传给后端的字段（删除多余字段 保持请求体干净）
  buildSaveData(type) {
    // console.log(type);
  }

  @Watch('visible')
  visibleWatcher(newVal: boolean, oldVal: boolean) {
    if (newVal) {
      this.buildSaveData(this.widgetClone.dataSource.dataSourceConfig);
      this.showDataSourceConfigDialog();
    }
  }

  @Watch('widget', { deep: true })
  widgetWatcher(newVal: WidgetModel, oldVal: WidgetModel) {
    this.widgetClone = SystemUtil.cloneDeep(newVal);
  }
}
