var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "configForm",
      attrs: {
        model: _vm.dataSourceConfigClone,
        "label-width": "100px",
        "label-suffix": "：",
        rules: _vm.rule,
      },
    },
    [
      _vm.dataSourceConfigClone.type
        ? _c(
            "el-tabs",
            {
              staticClass: "datasource-tabs",
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "datasource-tab-item",
                  attrs: {
                    label: _vm.$t("lang_column_configuration1"),
                    name: "column",
                  },
                },
                [
                  _vm.dataSourceConfig.type ==
                  _vm.widgetDataSourceConfigTypeEnum.FORM_CONFIG
                    ? [
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lang_auto_end_form_name"),
                                      prop: "formCode",
                                    },
                                  },
                                  [
                                    _c("treeselect", {
                                      ref: "formCodeRef",
                                      attrs: {
                                        normalizer: _vm.formNormalizer,
                                        appendToBody: "",
                                        zIndex: "9999",
                                        "disable-branch-nodes": true,
                                        options: _vm.formList,
                                      },
                                      on: { input: _vm.handleChangeForm },
                                      model: {
                                        value:
                                          _vm.dataSourceConfigClone.formCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataSourceConfigClone,
                                            "formCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataSourceConfigClone.formCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否可配置",
                                      prop: "configurable",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.dataSourceConfigClone
                                              .configurable,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataSourceConfigClone,
                                              "configurable",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataSourceConfigClone.configurable",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: true } },
                                          [_vm._v(" 是 ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v(" 否 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.widget.dataSource.type ==
                  _vm.widgetDataSourceTypeEnum.SIMPLE
                    ? [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lang_display_item"),
                                      prop: "columnConfig.customSimpleColumn",
                                    },
                                  },
                                  [
                                    _c("treeselect", {
                                      ref: "customSimpleColumnKeyRef",
                                      attrs: {
                                        normalizer: _vm.columnNormalizer,
                                        appendToBody: "",
                                        zIndex: "9999",
                                        "disable-branch-nodes": true,
                                        options: _vm.formWidgetList,
                                      },
                                      model: {
                                        value:
                                          _vm.dataSourceConfigClone.columnConfig
                                            .customSimpleColumn.key,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataSourceConfigClone
                                              .columnConfig.customSimpleColumn,
                                            "key",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataSourceConfigClone.columnConfig.customSimpleColumn.key",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.widget.dataType == _vm.widgetDataTypeEnum.TREE &&
                  _vm.dataSourceConfig.type ==
                    _vm.widgetDataSourceConfigTypeEnum.USER_CONFIG
                    ? [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "数据层级",
                                      prop: "dataLevel",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.dataSourceConfigClone.dataLevel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataSourceConfigClone,
                                              "dataLevel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataSourceConfigClone.dataLevel",
                                        },
                                      },
                                      _vm._l(_vm.dataLevels, function (item) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.id,
                                            attrs: { label: item.id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.widget.dataSource.type ==
                  _vm.widgetDataSourceTypeEnum.COMPLEX
                    ? [
                        _vm.dataSourceConfigClone.columnConfig &&
                        _vm.dataSourceConfigClone.columnConfig
                          .customComplexColumns &&
                        _vm.dataSourceConfigClone.columnConfig
                          .customComplexColumns.length == 0
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass: "add-btn-wrapper",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addPropConfig(null)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-plus" })]
                              ),
                            ]
                          : [
                              _c(
                                "el-row",
                                { staticClass: "condition-header" },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5, align: "center" } },
                                    [_vm._v(" 表单项 ")]
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5, align: "center" } },
                                    [_vm._v(" 别名 ")]
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4, align: "center" } },
                                    [_vm._v(" 是否结果 ")]
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4, align: "center" } },
                                    [_vm._v(" 是否显示 ")]
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3, align: "center" } },
                                    [_vm._v(" 排序 ")]
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3, align: "center" } },
                                    [_vm._v(" 操作 ")]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.dataSourceConfigClone.columnConfig
                                  .customComplexColumns,
                                function (item, index) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: index,
                                      staticClass: "condition-wrapper",
                                      attrs: { gutter: 10 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 5, align: "center" } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0px" } },
                                            [
                                              _c("treeselect", {
                                                ref: "columnPropRef",
                                                refInFor: true,
                                                staticStyle: { width: "80%" },
                                                attrs: {
                                                  normalizer:
                                                    _vm.columnNormalizer,
                                                  appendToBody: "",
                                                  zIndex: "9999",
                                                  "disable-branch-nodes": true,
                                                  options: _vm.formWidgetList,
                                                },
                                                on: {
                                                  select: function (val) {
                                                    return _vm.changeProp(
                                                      val,
                                                      item,
                                                      _vm.dataSourceConfigClone
                                                        .columnConfig
                                                        .customComplexColumns,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.key,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "key", $$v)
                                                  },
                                                  expression: "item.key",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 5, align: "center" } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0px" } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  size: "normal",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: item.title,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "title", $$v)
                                                  },
                                                  expression: "item.title",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4, align: "center" } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0px" } },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  label: "",
                                                  indeterminate: false,
                                                },
                                                model: {
                                                  value: item.visible,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "visible",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.visible",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4, align: "center" } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0px" } },
                                            [
                                              _c("el-checkbox", {
                                                attrs: {
                                                  label: "",
                                                  indeterminate: false,
                                                },
                                                model: {
                                                  value: item.display,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "display",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.display",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 3, align: "center" } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { "label-width": "0px" } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "number",
                                                  size: "normal",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: item.index,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "index",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "item.index",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "condition-btns",
                                          attrs: { span: 3 },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              type: "primary",
                                              circle: "",
                                              size: "small",
                                              icon: "el-icon-plus",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addPropConfig(item)
                                              },
                                            },
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              type: "primary",
                                              circle: "",
                                              size: "small",
                                              icon: "el-icon-delete",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removePropConfig(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.dataSourceConfigClone.conditionValueConfigs
                ? _c(
                    "el-tab-pane",
                    {
                      staticClass: "datasource-tab-item",
                      attrs: {
                        label: _vm.$t("lang_condition_value_configuration"),
                        name: "conditionValue",
                      },
                    },
                    _vm._l(
                      _vm.dataSourceConfigClone.conditionValueConfigs,
                      function (item, index) {
                        return _c(
                          "el-row",
                          {
                            key: item.queryCondition.name + index,
                            attrs: { gutter: 10 },
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "50px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t(item.queryCondition.name)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: _vm.$t("lang_list_name") },
                                  },
                                  [
                                    _c("treeselect", {
                                      ref: "formCodeRef",
                                      refInFor: true,
                                      attrs: {
                                        normalizer: _vm.formNormalizer,
                                        appendToBody: "",
                                        zIndex: "9999",
                                        "disable-branch-nodes": true,
                                        options: _vm.formList,
                                      },
                                      on: { input: _vm.handleChangeFormVal },
                                      model: {
                                        value: item.formCode,
                                        callback: function ($$v) {
                                          _vm.$set(item, "formCode", $$v)
                                        },
                                        expression: "item.formCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 10 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("lang_display_item"),
                                    },
                                  },
                                  [
                                    _c("treeselect", {
                                      ref: "customSimpleColumnKeyRef",
                                      refInFor: true,
                                      attrs: {
                                        normalizer: _vm.columnNormalizer,
                                        appendToBody: "",
                                        zIndex: "9999",
                                        "disable-branch-nodes": true,
                                        options: _vm.formWidgetList,
                                      },
                                      model: {
                                        value: item.formModelCode,
                                        callback: function ($$v) {
                                          _vm.$set(item, "formModelCode", $$v)
                                        },
                                        expression: "item.formModelCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _vm.dataSourceConfig.type == "APP_CONFIG" ||
              _vm.dataSourceConfig.type == "ARCHIVES_CONFIG"
                ? _c(
                    "el-tab-pane",
                    {
                      staticClass: "datasource-tab-item",
                      attrs: { label: "范围值配置", name: "conditionValue" },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                "label-width": "150px",
                                label: "类型范围",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.dataSourceConfigClone.scopeType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataSourceConfigClone,
                                        "scopeType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dataSourceConfigClone.scopeType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "不设置查询范围",
                                      value: 0,
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "登录人范围", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "自定义范围", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _vm.dataSourceConfigClone.scopeType == "2"
                            ? _c("bind-scope", {
                                attrs: {
                                  "select-scope-list": _vm.selectedScopeList,
                                },
                                on: { done: _vm.setSelectedScopeList },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataSourceConfigClone.type == "OPTION_CONFIG"
                ? _c(
                    "el-tab-pane",
                    {
                      staticClass: "datasource-tab-item",
                      attrs: {
                        label: _vm.$t("lang_option_configuration"),
                        name: "option",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "datasource-option-config" },
                        [
                          _vm._l(
                            _vm.dataSourceConfigClone.normalDataList,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "select-item" },
                                [
                                  _vm._l(_vm.formWidgetList, function (column) {
                                    return _c("el-input", {
                                      key: column.key,
                                      attrs: {
                                        placeholder: column.title,
                                        size: "small",
                                      },
                                      model: {
                                        value: item[column.key],
                                        callback: function ($$v) {
                                          _vm.$set(item, column.key, $$v)
                                        },
                                        expression: "item[column.key]",
                                      },
                                    })
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "close-btn select-line-icon",
                                      on: {
                                        click: function ($event) {
                                          return _vm.dataSourceConfigClone.normalDataList.splice(
                                            index,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-remove-outline",
                                      }),
                                    ]
                                  ),
                                ],
                                2
                              )
                            }
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "add-btn-wrapper",
                              staticStyle: { "margin-top": "20px" },
                              on: {
                                click: function ($event) {
                                  return _vm.addOption()
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        2
                      ),
                    ]
                  )
                : _c(
                    "el-tab-pane",
                    {
                      staticClass: "datasource-tab-item",
                      attrs: {
                        label: _vm.$t("lang_condition_configuration"),
                        name: "condition",
                      },
                    },
                    [
                      _vm.dataSourceConfigClone.type ==
                      "PROCESS_TASK_LOG_CONFIG"
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("lang_process_name"),
                                        prop: "processCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value:
                                              _vm.dataSourceConfigClone
                                                .processCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataSourceConfigClone,
                                                "processCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataSourceConfigClone.processCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.processList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.link.displayName,
                                                value: item.link.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "lang_is_the_operator_up_to_date"
                                        ),
                                        "label-width": "160px",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.dataSourceConfigClone
                                              .lastOperator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataSourceConfigClone,
                                              "lastOperator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataSourceConfigClone.lastOperator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "lang_is_the_node_up_to_date"
                                        ),
                                        "label-width": "160px",
                                      },
                                    },
                                    [
                                      _c("el-switch", {
                                        model: {
                                          value:
                                            _vm.dataSourceConfigClone.lastNode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataSourceConfigClone,
                                              "lastNode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataSourceConfigClone.lastNode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.dataSourceConfigClone.type
                        ? _c("ele-data-source-config-item", {
                            ref: "conditionConfigRef",
                            attrs: {
                              conditions: _vm.conditions,
                              conditionValueConfigs:
                                _vm.dataSourceConfigClone.conditionValueConfigs,
                              conditionConfig:
                                _vm.dataSourceConfigClone.conditionConfig,
                              dataSourceConfigType:
                                _vm.dataSourceConfigClone.type,
                              formCode: _vm.dataSourceConfigClone.formCode,
                              processCode:
                                _vm.dataSourceConfigClone.processCode,
                              widget: _vm.widget,
                            },
                            on: {
                              "update:conditionValueConfigs": function (
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.dataSourceConfigClone,
                                  "conditionValueConfigs",
                                  $event
                                )
                              },
                              "update:condition-value-configs": function (
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.dataSourceConfigClone,
                                  "conditionValueConfigs",
                                  $event
                                )
                              },
                              "update:conditionConfig": function ($event) {
                                return _vm.$set(
                                  _vm.dataSourceConfigClone,
                                  "conditionConfig",
                                  $event
                                )
                              },
                              "update:condition-config": function ($event) {
                                return _vm.$set(
                                  _vm.dataSourceConfigClone,
                                  "conditionConfig",
                                  $event
                                )
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
              _c(
                "el-tab-pane",
                {
                  staticClass: "datasource-tab-item",
                  attrs: {
                    label: _vm.$t("lang_sort_configuration1"),
                    name: "order",
                  },
                },
                [
                  _c("ele-data-source-order-config", {
                    ref: "orderConfigRef",
                    attrs: {
                      widgetTree: _vm.formWidgetList,
                      orderConfigs:
                        _vm.dataSourceConfigClone.columnConfig.orderConfigs,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }