















































































import { Component, Prop, Watch, InjectReactive, Vue } from 'vue-property-decorator';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
/**
 * input的配置项
 */
@Component({
  name: 'EleDataSourceOrderConfig',
  components: {}
})
export default class extends Vue {
  @Prop({
    default: () => {
      return [];
    }
  })
  orderConfigs!: any[];

  @Prop({
    default: () => {
      return [];
    }
  })
  widgetTree!: any[];

  orderConfigsClone: any[] = [];

  stringRules = [{ required: true, message: '请填写条件值', trigger: 'blur' }];

  @InjectReactive()
  formId: any;

  created() {
    this.initData();
  }
  /**
   * description 返显数据源数据
   * @param {}
   * @returns {}
   */
  initData() {
    this.orderConfigsClone = this.buildEchoData();
  }

  /**
   * 构建回显的数据
   */
  buildEchoData() {
    if (this.orderConfigs && this.orderConfigs.length) {
      return this.orderConfigs.map(item => {
        let tmp = { ...item };
        if (tmp.orderByColumns && tmp.orderByColumns.length) {
          tmp.orderByColumns = tmp.orderByColumns.map(column => {
            return { key: column };
          });
        }
        return tmp;
      });
    }
    return [];
  }

  /**
   * 表单可配置项的格式化
   * @param {number} param
   * @returns {number}
   */
  columnNormalizer(node) {
    return {
      id: node.key,
      label: this.$t(node.title),
      children: node.children
    };
  }

  // 添加新排序组
  addGroup(groupList: any, groupModal: any = null, groupIndex: number = 0) {
    let defaultGroupData: any = {
      order: true,
      orderByColumns: [{ key: null }]
    };
    if (groupList && groupModal) {
      if (this.validateGroup(groupModal)) {
        groupList.push(defaultGroupData);
      } else {
        this.$message.error(this.$t('lang_perfect_param_config') as string);
      }
    } else {
      groupList.push(defaultGroupData);
    }
  }
  // 移除排序组
  removeGroup(conditionGroupList, groupIndex) {
    conditionGroupList.splice(groupIndex, 1);
  }

  // 校验条件是否可以继续添加新组
  validateGroup(groupItem) {
    let flag = true;
    if (groupItem.orderByColumns && groupItem.orderByColumns.length) {
      groupItem.orderByColumns.forEach(column => {
        if (!column.key) {
          flag = false;
        }
      });
    } else {
      flag = false;
    }
    return flag;
  }

  // 添加规则条件
  addCondition(orderByColumns, conditionIndex, conditionItem) {
    let defaultData = { key: null };
    if (this.validateCond(conditionItem)) {
      orderByColumns.push(defaultData);
    } else {
      this.$message.error(this.$t('lang_perfect_param_config') as string);
    }
  }
  // 移除该条件
  removeCondition(orderByColumns, conditionIndex) {
    if (orderByColumns.length == 1) {
      this.$message.error(this.$t('lang_first_unremovable') as string);
    } else {
      orderByColumns.splice(conditionIndex, 1);
    }
  }
  // 校验条件是否填写完毕
  validateCond(conditionItem) {
    let flag = false;
    if (conditionItem.key) {
      flag = true;
    }
    return flag;
  }

  /**
   * 父级通过ref调用改方法获取配置结果
   */
  getOrderConfigs() {
    if (this.orderConfigsClone && this.orderConfigsClone.length) {
      if (!this.validateConfig()) {
        this.$message.error(this.$t('lang_perfect_param_config') as string);
        return false;
      }
      return this.orderConfigsClone.map(item => {
        let tmp = { ...item };
        if (tmp.orderByColumns && tmp.orderByColumns.length) {
          tmp.orderByColumns = tmp.orderByColumns.map(column => {
            return column.key;
          });
        } else {
          tmp.orderByColumns = [];
        }
        return tmp;
      });
    }
    return [];
  }

  /**
   * 校验最终配置
   */
  validateConfig() {
    let flag = true;
    if (this.orderConfigsClone && this.orderConfigsClone.length) {
      this.orderConfigsClone.forEach(item => {
        if (!this.validateGroup(item)) {
          flag = false;
        }
      });
    }
    return flag;
  }

  @Watch('orderConfigs', { deep: true })
  orderConfigsWatcher(value: any, oldValue: any) {
    this.initData();
  }
}
