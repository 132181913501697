



import { Component, Prop, Watch, InjectReactive } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import dict from '@/mixins/dict';
import { WidgetData, WidgetModel } from '@/models/form/WidgetModel';
/**
 * input的配置项
 */
@Component({
  name: 'EleDataSourceConfigItem',
  components: {}
})
export default class extends mixins(dict) {
  @Prop({
    default: () => {
      return WidgetData;
    }
  })
  widget!: WidgetModel;

  @InjectReactive()
  formId: any;

  created() {
    console.log(this.$store.state.form.widgetConfigStruct);
  }
}
