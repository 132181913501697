var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      staticClass: "affect-compontent-tabs",
      attrs: { type: "border-card" },
      model: {
        value: _vm.triggerType,
        callback: function ($$v) {
          _vm.triggerType = $$v
        },
        expression: "triggerType",
      },
    },
    _vm._l(_vm.triggers, function (tabItem) {
      return _c(
        "el-tab-pane",
        {
          key: tabItem.type,
          staticClass: "affect-compontent-tabpane",
          attrs: { label: _vm.$t(tabItem.name), name: tabItem.type },
        },
        [
          tabItem.type == _vm.widgetActionTypeEnum.COMPONENTS
            ? [
                _c("div", { staticClass: "affect-compontent-columns" }, [
                  _c("div", [_vm._v(_vm._s(_vm.$t("lang_form_item")))]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("lang_data_source_onfiguration"))),
                  ]),
                  _c("div", [
                    _vm._v(_vm._s(_vm.$t("lang_default_value_configuration"))),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.$t("lang_jurisdiction")))]),
                  _c("div", [_vm._v(_vm._s(_vm.$t("lang_operation")))]),
                ]),
                _vm._l(
                  _vm.affectedComponents,
                  function (affectedItem, affectedItemIndex) {
                    return _c(
                      "el-form",
                      {
                        key: affectedItem.id,
                        ref: "affectedForm",
                        refInFor: true,
                        staticClass: "affect-compontent-form",
                        attrs: {
                          model: affectedItem,
                          "label-width": "100px",
                          rules: _vm.rule,
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "affect-compontent-item",
                            attrs: {
                              prop: "compCode",
                              label: "",
                              "label-width": "0px",
                            },
                          },
                          [
                            _c("treeselect", {
                              staticClass: "cus-treeselect",
                              attrs: {
                                "disable-branch-nodes": true,
                                options: _vm._formWidgetTree,
                                normalizer: _vm.formWidgetNormalizer,
                                "append-to-body": "",
                                zIndex: "9999",
                                placeholder: _vm.$t("lang_components"),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.changeEffectWidget(
                                    affectedItem,
                                    _vm.affectedComponents,
                                    affectedItemIndex
                                  )
                                },
                              },
                              model: {
                                value: affectedItem.compCode,
                                callback: function ($$v) {
                                  _vm.$set(affectedItem, "compCode", $$v)
                                },
                                expression: "affectedItem.compCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "affect-compontent-item",
                            attrs: { label: "", "label-width": "0px" },
                          },
                          [
                            _vm.judgeDataSourceConfigable(affectedItem)
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "small",
                                      placeholder: _vm.$t("lang_source_type"),
                                    },
                                    model: {
                                      value: affectedItem.dataSourceConfigType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          affectedItem,
                                          "dataSourceConfigType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "affectedItem.dataSourceConfigType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.getDataSourceConfigs(affectedItem),
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.type,
                                        attrs: {
                                          label: item.name,
                                          value: item.type,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.openDataSourceConfigDialog(
                                              item,
                                              affectedItem,
                                              affectedItemIndex,
                                              _vm.affectedComponents
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("el-select", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    value: "",
                                    placeholder: _vm.$t("lang_source_type"),
                                    size: "small",
                                    disabled: "",
                                  },
                                }),
                          ],
                          1
                        ),
                        _vm.judgeDefaultDataConfigable(affectedItem)
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "affect-compontent-item",
                                  attrs: { label: "", "label-width": "0px" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "lang_default_value_configuration"
                                        ),
                                        size: "small",
                                      },
                                      model: {
                                        value:
                                          affectedItem.defaultDataConfigType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            affectedItem,
                                            "defaultDataConfigType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "affectedItem.defaultDataConfigType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.getDefaultDataConfigs(affectedItem),
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.type,
                                          attrs: {
                                            label: item.name,
                                            value: item.type,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.openDefaultDataConfigDialog(
                                                item,
                                                affectedItem,
                                                affectedItemIndex,
                                                _vm.affectedComponents
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "affect-compontent-item",
                                  attrs: { label: "", "label-width": "0px" },
                                },
                                [
                                  _c("el-select", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      value: "",
                                      placeholder: _vm.$t(
                                        "lang_default_value_configuration"
                                      ),
                                      size: "small",
                                      disabled: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                        _c(
                          "el-form-item",
                          {
                            staticClass: "affect-compontent-item",
                            attrs: { label: "", "label-width": "0px" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t("lang_please_select"),
                                  size: "small",
                                  disabled:
                                    !affectedItem.widget ||
                                    !affectedItem.widget.compCode,
                                },
                                on: {
                                  change: function (val) {
                                    return _vm.changeEffectWidgetOperationAuth(
                                      val,
                                      affectedItem,
                                      affectedItemIndex,
                                      _vm.affectedComponents
                                    )
                                  },
                                },
                                model: {
                                  value: affectedItem.operation,
                                  callback: function ($$v) {
                                    _vm.$set(affectedItem, "operation", $$v)
                                  },
                                  expression: "affectedItem.operation",
                                },
                              },
                              _vm._l(_vm.operationAuthList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "affect-compontent-item" },
                          [
                            _c("el-button", {
                              staticStyle: { "margin-bottom": "3px" },
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                circle: "",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.addAffectedComponents(
                                    affectedItemIndex,
                                    _vm.affectedComponents
                                  )
                                },
                              },
                            }),
                            _c("el-button", {
                              staticStyle: {
                                "margin-left": "5px",
                                "margin-bottom": "3px",
                              },
                              attrs: {
                                type: "primary",
                                icon: "el-icon-delete",
                                circle: "",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeAffectedComponents(
                                    _vm.affectedComponents,
                                    affectedItemIndex
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  }
                ),
                _vm.affectedComponents && _vm.affectedComponents.length == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "add-btn-wrapper",
                        on: {
                          click: function ($event) {
                            return _vm.addAffectedComponents(
                              0,
                              _vm.affectedComponents
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-plus" })]
                    )
                  : _vm._e(),
                _c("ele-data-source-config-dialog", {
                  attrs: {
                    visible: _vm.showEffectWidgetDataSourceDialog,
                    widget: _vm.currentEffectWidget.widget,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showEffectWidgetDataSourceDialog = $event
                    },
                    save: _vm.changeEffectWidgetDataSourceConfig,
                  },
                }),
                _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.$t("lang_set_default_values"),
                      visible: _vm.showEffectWidgetDefaultValueDialog,
                      width: "60%",
                      "close-on-click-modal": false,
                      "append-to-body": "",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.showEffectWidgetDefaultValueDialog = $event
                      },
                    },
                  },
                  [
                    _vm.showEffectWidgetDefaultValueDialog
                      ? _c("ele-default-value-config", {
                          ref: "defaultValueConfigRef",
                          refInFor: true,
                          staticStyle: {
                            width: "300px",
                            "min-height": "300px",
                            "margin-left": "20px",
                          },
                          attrs: { widget: _vm.currentEffectWidget.widget },
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      { attrs: { slot: "footer" }, slot: "footer" },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.showEffectWidgetDefaultValueDialog = false
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: _vm.changeEffectWidgetDefaultValueConfig,
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : tabItem.type == _vm.widgetActionTypeEnum.API
            ? void 0
            : tabItem.type == _vm.widgetActionTypeEnum.LINK
            ? void 0
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }