


















































































































































































import { Component, Prop, Watch, InjectReactive } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import dict from '@/mixins/dict';
import {
  WidgetDataSourceConditionConfigData,
  WidgetDataSourceConditionConfigModel,
  WidgetDataSourceConditionData,
  WidgetDataSourceConditionGroupData,
  WidgetDataSourceConfigTypeEnum
} from '@/models/form/WidgetDataSourceModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetDataTypeEnum } from '@/models/form/WidgetModel';
import { getBusinessDataSource } from '@/api/form-design/FormApi';
import { getCompares, getFormDataSourceMap } from '../utils';
/**
 * input的配置项
 */
@Component({
  name: 'EleDataSourceConfigItem',
  components: {}
})
export default class extends mixins(dict) {
  @Prop({
    default: () => {
      return WidgetDataSourceConditionConfigData;
    }
  })
  conditionConfig!: WidgetDataSourceConditionConfigModel;

  @Prop({
    default: () => {
      return [];
    }
  })
  conditions!: any[];

  @Prop({
    default: null
  })
  formCode!: string; // 数据源类型为'FORM_CONFIG'时，选择的表单

  @Prop({
    default: null
  })
  processCode!: string; // 数据源类型为'PROCESS_TASK_LOG_CONFIG'时，选择的流程
  @Prop({
    default: ''
  })
  dataSourceConfigType!: string;

  @Prop({
    default: () => {
      return [];
    }
  })
  conditionValueConfigs: any;

  widgetDataTypeEnum: any = WidgetDataTypeEnum;
  conditionGroups: any = WidgetDataSourceConditionGroupData;

  groupKey: string = 'group1'; // 线条的 属性设置弹框内的折叠面板 当前激活的面板
  valueTypes: any[] = [
    { id: 'INPUT', label: this.$t('lang_input_value') },
    { id: 'SELECT', label: this.$t('lang_condition_value') }
  ];

  commonRules = {
    key: [{ required: true, message: '请选择条件项', trigger: 'change' }],
    value: [{ required: true, message: '请选择类型', trigger: ['change', 'blur'] }],
    compare: [{ required: true, message: '请选择运算符', trigger: 'change' }],
    logical: [{ required: true, message: '请选择逻辑关系', trigger: 'change' }]
  };
  stringRules = [{ required: true, message: '请填写条件值', trigger: 'blur' }];

  numberRules = [
    {
      required: true,
      type: 'number',
      message: '请填写条件值',
      trigger: 'blur'
    }
  ];
  singleRules = [
    {
      required: true,
      message: '请选择条件值',
      trigger: 'change'
    }
  ];
  arrayRules = [
    {
      required: true,
      type: 'array',
      message: '请选择条件值',
      trigger: 'change'
    }
  ];
  dateRules = [{ required: true, type: 'date', message: '请选择日期', trigger: 'change' }];

  @InjectReactive()
  formId: any;

  get _dataSourceMaps() {
    return getFormDataSourceMap(this.formId);
  }

  created() {
    this.initData();
  }
  /**
   * description 返显数据源数据
   * @param {}
   * @returns {}
   */
  initData() {
    let conditionGroups = SystemUtil.cloneDeep(this.conditionConfig.dataListConditions);
    conditionGroups.forEach((groupItem: any) => {
      groupItem.conditions.forEach(conditionItem => {
        if (conditionItem.key) {
          // 构建每行条件的值得数据源
          conditionItem.dataSource = [];
          this.buildWidgetDataSource(conditionItem);
        }

        if (conditionItem.key && conditionItem.value) {
          // 构建每行条件的值的回显
          if (conditionItem.source == 'SELECT') {
            let result: any[] = conditionItem.value.map(item => {
              return item.value;
            });
            if (this.isCollectorOrTree(conditionItem)) {
              conditionItem.result = result;
            } else {
              conditionItem.result = result[0];
            }
          } else if (conditionItem.source == 'INPUT') {
            let result: any = '';
            if (conditionItem.value[0]) {
              result = conditionItem.value[0].value;
            }
            conditionItem.result = result;
          }
        } else {
          conditionItem.result = null;
        }
      });
    });
    this.conditionGroups = conditionGroups;
  }

  isCollectorOrTree(conditionItem) {
    return (
      conditionItem.filter &&
      (conditionItem.filter.type == this.widgetDataTypeEnum.TREE || conditionItem.filter.type == this.widgetDataTypeEnum.COLLECTOR)
    );
  }
  /**
   * description  treeselect组件需要id label字段，所以转化数据字段
   * @param {object} node 每一级的数据
   * @returns {object}
   */
  normalizerHandler(node) {
    return {
      id: node.code,
      label: node.displayName || this.$t(node.text),
      isDisabled: node.disabled == true
    };
  }

  // 添加新规则组
  addGroup(groupList: any, groupModal: any = null, groupIndex: number = 0) {
    if (groupList && groupModal) {
      if (this.validateGroup(groupModal, groupIndex)) {
        let groupKey = 'group' + (groupList.length + 1);
        groupList.push({
          key: groupKey,
          logical: 'AND',
          conditions: [SystemUtil.cloneDeep(WidgetDataSourceConditionData)],
          first: false
        });
        this.$nextTick(() => {
          this.groupKey = groupKey;
        });
      } else {
        this.$message.error(this.$t('lang_perfect_param_config') as string);
      }
    } else {
      let groupKey = 'group' + (groupList.length + 1);
      groupList.push({
        key: groupKey,
        logical: 'AND',
        conditions: [SystemUtil.cloneDeep(WidgetDataSourceConditionData)],
        first: true
      });
      this.$nextTick(() => {
        this.groupKey = groupKey;
      });
    }
  }
  // 移除规则组
  removeGroup(conditionGroupList, groupIndex) {
    conditionGroupList.splice(groupIndex, 1);
  }

  // 校验条件是否可以继续添加新组
  validateGroup(groupModal, groupIndex) {
    if (!groupModal && groupIndex == 0) {
      return true;
    }
    let flag = false;
    let formRef = '';
    let that = this;
    groupModal.conditions.every((item, condIndex) => {
      formRef = 'ruleForm_' + groupIndex;
      let ref = that.$refs[formRef][condIndex] as any;
      ref.validate(valid => {
        if (valid) {
          flag = true;
        } else {
          flag = false;
        }
        return flag;
      });
      return flag;
    });
    return flag;
  }

  // 添加规则条件
  addCondition(conditions, conditionIndex, formRef) {
    let defaultData = SystemUtil.cloneDeep(WidgetDataSourceConditionData);
    if (conditions.length) {
      defaultData.first = false;
    }
    if (formRef) {
      if (this.validateCond(formRef, conditionIndex)) {
        conditions.push(defaultData);
      }
    } else {
      conditions.push(defaultData);
    }
  }
  // 移除该条件
  removeCondition(conditions, conditionIndex) {
    conditions.splice(conditionIndex, 1);
  }
  // 校验条件是否填写完毕
  validateCond(formRef, formIndex) {
    let flag = false;
    let ref = this.$refs[formRef][formIndex] as any;
    ref.validate(valid => {
      if (valid) {
        flag = true;
      } else {
        this.$message.error(this.$t('lang_perfect_param_config') as string);
        flag = false;
      }
    });
    return flag;
  }

  changeConditionItemKey(conditionItem, conditions, conditionIndex) {
    conditionItem.compare = '';
    this.changeConditionValueSource(conditionItem, conditions, conditionIndex);
  }

  getOperatorList(conditionItem) {
    let operatorList: Record<string, any>[] = [];
    let curConditon: any = this.conditions.find(item => {
      return item.key == conditionItem.key;
    });
    if (curConditon) {
      operatorList = getCompares(curConditon.filter.truthType);
    }
    return operatorList;
  }

  // 获取每行条件的值的数据源(非表单字段条件)
  buildStaticConfigDataSource(conditionItem) {
    let dataSource: any[] = [];
    let curConditon: any = this.conditions.find(item => {
      return item.key == conditionItem.key;
    });
    if (curConditon) {
      let dataPoolType = curConditon.filter.dataPoolType || '';
      this._dataSourceMaps.forEach(group => {
        let dataSourcePart = group.data;
        if (group.valueType == 'FIXED_VALUE') {
          dataSourcePart.forEach(item => {
            if (item.type == dataPoolType) {
              if (item.dataValueItems) {
                dataSource = dataSource.concat(item.dataValueItems);
              }
            }
          });
        } else {
          dataSource = dataSource.concat(group.data);
        }
      });
    }
    conditionItem.dataSource = dataSource;
  }

  // 获取每行条件的值的数据源（表单字段条件）
  buildDynamicConfigDataSource(conditionItem) {
    let curConditon: any = this.conditions.find(item => {
      return item.key == conditionItem.key;
    });
    if (curConditon) {
      let dataSource: any[] = [];
      let dataPoolType = curConditon.filter.dataPoolType || '';
      if (dataPoolType) {
        let params: any = {
          dataPoolType: dataPoolType,
          param: {
            formModelCode: conditionItem.key
          }
        };
        if (this.formCode) {
          params.param.formCode = this.formCode;
        } else if (this.processCode) {
          params.param.processCode = this.processCode;
        } else if (conditionItem.formCode) {
          params.param.formCode = conditionItem.formCode;
        }
        getBusinessDataSource(params).then((res: Record<string, any>) => {
          if (res.code == '1' && res.data) {
            this._dataSourceMaps.forEach(group => {
              let dataSourcePart = res.data;
              if (group.valueType == 'FIXED_VALUE') {
                dataSourcePart.forEach(item => {
                  if (item.type == dataPoolType) {
                    if (item.dataValueItems) {
                      dataSource = dataSource.concat(item.dataValueItems);
                    }
                  }
                });
              } else {
                dataSource = dataSource.concat(group.data);
              }
            });
          }
          conditionItem.dataSource = dataSource;
        });
      } else {
        this._dataSourceMaps.forEach(group => {
          if (group.valueType != 'FIXED_VALUE') {
            dataSource = dataSource.concat(group.data);
          }
        });
        conditionItem.dataSource = dataSource;
      }
    }
  }

  // zaa 新增 新增 列表组件获取每行条件的值的数据源（表单字段条件）
  buildDynamicConfigDataSource2(conditionItem) {
    let curConditon: any = this.conditions.find(item => {
      return item.key == conditionItem.key;
    });
    if (curConditon) {
      let dataSource: any[] = [];
      let dataPoolType = curConditon.filter.dataPoolType || '';
      if (dataPoolType) {
        let params: any = {
          dataPoolType: dataPoolType,
          param: {
            formModelCode: conditionItem.formModelCode,
            formCode: conditionItem.formCode
          }
        };
        getBusinessDataSource(params).then((res: Record<string, any>) => {
          if (res.code == '1' && res.data) {
            this._dataSourceMaps.forEach(group => {
              let dataSourcePart = res.data;
              if (group.valueType == 'FIXED_VALUE') {
                dataSourcePart.forEach(item => {
                  if (item.type == dataPoolType) {
                    if (item.dataValueItems) {
                      dataSource = dataSource.concat(item.dataValueItems);
                    }
                  }
                });
              } else {
                dataSource = dataSource.concat(group.data);
              }
            });
          }
          conditionItem.dataSource = dataSource;
        });
      } else {
        this._dataSourceMaps.forEach(group => {
          if (group.valueType != 'FIXED_VALUE') {
            dataSource = dataSource.concat(group.data);
          }
        });
        conditionItem.dataSource = dataSource;
      }
    }
  }

  // 获取每行条件的值的数据源
  buildWidgetDataSource(conditionItem) {
    if (this.formCode || this.processCode || this.dataSourceConfigType === WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
      this.buildDynamicConfigDataSource(conditionItem);
    } else {
      this.buildStaticConfigDataSource(conditionItem);
    }
  }

  /**
   * 修改值类型时（输入值，条件值）
   * @param {number} param
   * @returns {number}
   */
  changeConditionValueSource(conditionItem, conditions, conditionIndex) {
    conditionItem.value = null;
    let curConditon: any = this.conditions.find(item => {
      return item.key == conditionItem.key;
    });
    conditionItem.filter = {
      type: curConditon.filter.type,
      dataPoolType: curConditon.filter.dataPoolType
    };
    conditionItem.tableName = curConditon.tableName;

    // zaa 新增
    if (this.conditionValueConfigs && this.conditionValueConfigs.length) {
      this.conditionValueConfigs.map(item => {
        if (item.queryCondition.key == conditionItem.key) {
          conditionItem.formModelCode = item.formModelCode;
          conditionItem.formCode = item.formCode;
          this.buildDynamicConfigDataSource2(conditionItem);
          return;
        }
      });
    }
    if (this.formCode || this.processCode || this.dataSourceConfigType === WidgetDataSourceConfigTypeEnum.OPTION_CONFIG) {
      this.buildDynamicConfigDataSource(conditionItem);
    } else {
      this.buildStaticConfigDataSource(conditionItem);
    }
    conditionItem.value = [];
    conditionItem.result = null;
    conditions.splice(conditionIndex, 1, conditionItem);
  }

  // 修改条件值时
  changeConditionValue(conditionItem: any, groupIndex: number, conditionIndex: number) {
    if (conditionItem.source == 'SELECT') {
      let value: any[] = [];
      let treeselectRef = 'condValue_' + groupIndex + '_' + conditionIndex;
      let selectedNodes = (this.$refs[treeselectRef][0] as any).selectedNodes;
      selectedNodes.forEach(item => {
        value.push({ type: item.raw.valueType, value: item.raw.code });
      });
      conditionItem.value = value;
    } else {
      conditionItem.value = [{ type: 'FIXED_VALUE', value: conditionItem.result }];
    }
  }

  // 校验所有规则组
  public validateAll(): PromiseLike<boolean> {
    return new Promise(resolve => {
      let arr: Promise<boolean>[] = [];
      Object.keys(this.$refs)
        .filter(key => /ruleForm_\d/g.test(key))
        .forEach(key => {
          (this.$refs[key] as any[]).forEach((form: any) => {
            arr.push(form.validate());
          });
        });
      Promise.all(arr)
        .then(result => {
          if (result.indexOf(false) >= 0) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch(e => {
          console.log(e);
          resolve(false);
        });
    });
  }
  getDataListConditions() {
    return SystemUtil.cloneDeep(this.conditionGroups);
  }

  @Watch('conditions', { deep: true })
  conditionConfigWatcher(value: any, oldValue: any) {
    this.initData();
  }
}
