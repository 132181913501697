

































































































import { Vue, Component, Prop, Watch, InjectReactive } from 'vue-property-decorator';
import EleFormulaConfig from './ele-formula-config.vue';
import EleDialogList from '../elements/ele-dialog-list.vue';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import { WidgetModel } from '@/models/form/WidgetModel';
import { getWidgetDataSource } from '@/api/form-design/FormApi';
import {
  WidgetDataSourceConfigTypeEnum,
  WidgetDataSourceTypeEnum,
  WidgetDefaultDataConfigData,
  WidgetDefaultDataConfigType
} from '@/models/form/WidgetDataSourceModel';
import { getFormConfig, getFormParams } from '../utils';
@Component({
  name: 'WidgetDefaultDataConfig',
  components: {
    EleFormulaConfig,
    EleDialogList
  }
})
export default class extends Vue {
  @Prop()
  widget!: WidgetModel;

  defaultDataConfigClone: any = {};
  widgetDataSource: any[] = [];
  widgetDefaultValue: any = null; // 默认值
  widgetDefaultDataValueKey: any = null; // 默认值是参数时，选择参数的dataValue属性（选择当前登录人的账号）
  widgetDefaultModelValueKey: any = null; // 默认值是参数时，选择参数的modelValue属性（选择当前登录人的账号）
  formArgumentPropTree: any[] = [];
  dialogListData: any[] = [];

  // 页面加载初始数据完毕
  isInitData: boolean = false;

  @InjectReactive()
  formId: any;

  // 获取reality全局默认映射关系
  get _widgetRealitysMap() {
    return this.$store.getters.widgetRealitysMap;
  }
  // 默认值配置类型
  get _defaultDataConfigs() {
    let dataSourceTypes: any[] = [];
    if (this.widget && this.widget.compCode) {
      let defaultDataStruct = this.$store.getters.defaultDataStruct;
      let widgetConfigMapping = this.$store.getters.widgetConfigMapping;
      let compType = this.widget.compType;
      let defaultDataTypeKeys = widgetConfigMapping.find(item => item.type == compType).defaultDataConfigTypes;
      if (defaultDataTypeKeys) {
        defaultDataTypeKeys.forEach(typeKey => {
          dataSourceTypes.push(defaultDataStruct[typeKey]);
        });
      }
    }
    return dataSourceTypes;
  }

  // 表单参数
  get _formArguments() {
    return getFormParams(this.formId);
  }

  created() {
    this.initData();
  }

  initData() {
    this.isInitData = false;
    if (this.widget && this.widget.compCode) {
      if (!this.widget.dataSource.defaultDataConfig) {
        this.defaultDataConfigClone = SystemUtil.cloneDeep(WidgetDefaultDataConfigData);
      } else {
        this.defaultDataConfigClone = SystemUtil.cloneDeep(this.widget.dataSource.defaultDataConfig);
      }
      this.buildFormArgumentPropTree();
      this.buildWidgetValueItems();
      this.buildWidgetDefaultValue();
    }
    this.$nextTick(() => {
      this.isInitData = true;
    });
  }

  // 配置表单项（选择类型的）默认值时，构造其数据源
  buildWidgetValueItems(pageInfo?: any) {
    if (this.widget.compCode) {
      if (this.widget.dataSource && this.widget.dataSource.dataSourceConfig) {
        if (!pageInfo) {
          pageInfo = {
            pageIndex: 1,
            pageSize: 10
          };
        }
        let currentDataSourceConfig = this.widget.dataSource.dataSourceConfig;
        if (currentDataSourceConfig && currentDataSourceConfig.type) {
          let params: any = {
            components: this.widget,
            page: pageInfo,
            formId: this.formId
          };
          getWidgetDataSource(params).then((res: any) => {
            if (res.code == '1' && res.data) {
              if (this.widget.dataSource.type == WidgetDataSourceTypeEnum.SIMPLE) {
                let simpleDataSource: any[] = res.data.simpleDataList || [];
                simpleDataSource.forEach(item => {
                  if (item.formModelCode == this.widget.compCode) {
                    this.widgetDataSource = item.dataList || [];
                  }
                });
              } else if (this.widget.dataSource.type == WidgetDataSourceTypeEnum.COMPLEX) {
                let complexDataSource: any[] = res.data.complexDataList || [];
                complexDataSource.forEach(item => {
                  if (item.formModelCode == this.widget.compCode) {
                    this.dialogListData = item.pageInfo;
                  }
                });
              }
            }
          });
        }
      }
    }
  }

  // 构建表单项的默认值
  buildWidgetDefaultValue() {
    let currentDeafultConfig: any = this.widget.dataSource.defaultDataConfig;
    if (currentDeafultConfig) {
      if (currentDeafultConfig.type == WidgetDefaultDataConfigType.FORMULA) {
        this.widgetDefaultValue = currentDeafultConfig.formula;
      } else if (currentDeafultConfig.type == WidgetDefaultDataConfigType.FORM_ARGUMENT) {
        if (currentDeafultConfig.argumentList && currentDeafultConfig.argumentList.length) {
          this.widgetDefaultValue = currentDeafultConfig.argumentList[0].argumentName;
          this.widgetDefaultDataValueKey = currentDeafultConfig.argumentList[0].dataValueKey;
          this.widgetDefaultModelValueKey = currentDeafultConfig.argumentList[0].modelValueKey;
          this.buildFormArgumentPropTree();
        } else {
          this.widgetDefaultValue = null;
          this.widgetDefaultDataValueKey = null;
          this.widgetDefaultModelValueKey = null;
        }
      } else if (currentDeafultConfig.complexDefaultDataList && currentDeafultConfig.complexDefaultDataList.length) {
        this.widgetDefaultValue = currentDeafultConfig.complexDefaultDataList;
      } else if (currentDeafultConfig.simpleDefaultDataList && currentDeafultConfig.simpleDefaultDataList.length) {
        if (!this.widget.properties.multiple) {
          this.widgetDefaultValue = currentDeafultConfig.simpleDefaultDataList[0];
        } else {
          this.widgetDefaultValue = currentDeafultConfig.simpleDefaultDataList;
        }
      } else {
        this.widgetDefaultValue = null;
      }
    } else {
      this.widgetDefaultValue = null;
      this.widgetDefaultDataValueKey = null;
      this.widgetDefaultModelValueKey = null;
    }
  }

  /**
   * 默认值为表单参数时，构建改参数的属性
   */
  buildFormArgumentPropTree() {
    if (this.defaultDataConfigClone.type == WidgetDefaultDataConfigType.FORM_ARGUMENT) {
      let formConfig = getFormConfig(this.formId);
      let curFormArgument = formConfig.container.paramsOptions.find(item => item.name == this.widgetDefaultValue);
      if (curFormArgument && this._widgetRealitysMap[curFormArgument.type.reality]) {
        let argumentProps = this._widgetRealitysMap[curFormArgument.type.reality].columns;
        if (argumentProps) {
          this.formArgumentPropTree = argumentProps;
          return;
        }
      }
    }
    this.formArgumentPropTree = [];
  }

  /**
   * 改变默认值类型时
   */
  changeDefaultDataType(val) {
    if (val) {
      let curType = this._defaultDataConfigs.find(item => item.type == val);
      this.defaultDataConfigClone.name = curType.name;
      if (val == 'MULTIPLE_DATA') {
        this.buildWidgetValueItems();
      }
    } else {
      this.defaultDataConfigClone = SystemUtil.cloneDeep(WidgetDefaultDataConfigData);
    }
    this.changeDefaultValueConfig();
  }

  /**
   * 修改组件默认值时
   * @param {number} param
   * @returns {number}
   */
  changeDefaultValue(val, instanceId) {
    if (this.isInitData) {
      if (val) {
        if (this.defaultDataConfigClone.type == WidgetDefaultDataConfigType.FORMULA) {
          this.defaultDataConfigClone.formula = val;
        } else if (this.defaultDataConfigClone.type == WidgetDefaultDataConfigType.FORM_ARGUMENT) {
          this.defaultDataConfigClone.argumentList = [{ argumentName: val }];
          this.widgetDefaultDataValueKey = null;
          this.widgetDefaultModelValueKey = null;
          this.buildFormArgumentPropTree();
        } else if (this.widget.dataSource.type == WidgetDataSourceTypeEnum.COMPLEX) {
          if (this.widget.compType == 'dialogList') {
            this.defaultDataConfigClone.complexDefaultDataList = val.dataValue;
          }
        } else {
          if (!Array.isArray(val)) {
            this.defaultDataConfigClone.simpleDefaultDataList = [val];
          } else {
            this.defaultDataConfigClone.simpleDefaultDataList = val;
          }
        }
      } else {
        this.defaultDataConfigClone.simpleDefaultDataList = [];
        this.defaultDataConfigClone.complexDefaultDataList = [];
        this.defaultDataConfigClone.formula = [];
      }
      this.changeDefaultValueConfig();
    }
  }

  /**
   * 是否是对象类型的表单参数
   */
  isComplexArgument(argumentKey) {
    if (argumentKey) {
      let formParams: any[] = getFormParams(this.formId);
      let curFormArgument = formParams.find(item => item.code == argumentKey);
      if (curFormArgument && curFormArgument.reality != 'none' && curFormArgument.value == 'DEFINITION') {
        return true;
      }
    }
    return false;
  }

  /**
   * 修改argument的属性
   */
  changeArgumentDataValueKey(val) {
    if (this.widgetDefaultValue) {
      this.defaultDataConfigClone.argumentList = [
        { argumentName: this.widgetDefaultValue, dataValueKey: val, modelValueKey: this.widgetDefaultModelValueKey }
      ];
    }
    if (this.isInitData) {
      this.changeDefaultValueConfig();
    }
  }

  /**
   * 修改argument的属性
   */
  changeArgumentModelValueKey(val) {
    if (this.widgetDefaultValue) {
      this.defaultDataConfigClone.argumentList = [
        { argumentName: this.widgetDefaultValue, dataValueKey: this.widgetDefaultDataValueKey, modelValueKey: val }
      ];
    }
    if (this.isInitData) {
      this.changeDefaultValueConfig();
    }
  }

  /**
   * 修改默认值改变，向父级emit
   */
  changeDefaultValueConfig() {
    this.$emit('change', this.getDefaultValueConfig());
  }

  /**
   * 父级页面通过ref调用该方法获取默认值配置
   */
  getDefaultValueConfig() {
    if (this.defaultDataConfigClone.type == '') {
      return null;
    }
    return SystemUtil.cloneDeep(this.defaultDataConfigClone);
  }

  @Watch('widget', { deep: true })
  widgetWatcher(newVal: WidgetModel, oldVal: WidgetModel) {
    if (newVal.id != oldVal.id) {
      this.initData();
    }
  }

  /**
   * 固定数据下拉树格式化
   */
  defaultDataFormater(node) {
    return {
      id: node.code,
      label: this.$t(node.text),
      children: node.children,
      isDisabled: node.disabled == true
    };
  }
  fixedDataFormater(node) {
    let disabled = node.disabled;
    if (node.children) {
      disabled = false;
    }
    return {
      id: node.code,
      label: this.$t(node.text),
      children: node.children,
      isDisabled: disabled
    };
  }

  /**
   * 表单参数的属性
   */
  propNormalizer(node) {
    return {
      id: node.key,
      label: node.title,
      children: node.children
    };
  }
}
