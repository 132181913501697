import HttpUtil from '@/utils/HttpUtil';

export default {
  // 根据应用类型获取应用id
  tree(params) {
    var url = process.env.VUE_APP_SERVER_CODE + 'application/query/list';
    return HttpUtil.post(url, params);
  },

  //获取左侧菜单
  page(params) {
    var url = process.env.VUE_APP_SERVER_CODE + 'application/query/page';
    return HttpUtil.post(url, params);
  },

  // 保存
  save(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/save';
    return HttpUtil.post(url, params);
  },

  // 删除
  delete(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/delete';
    return HttpUtil.get(url, params);
  },

  // 复制
  copy(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/copy';
    return HttpUtil.get(url, params);
  },

  // 详情
  detail(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/query/single';
    return HttpUtil.get(url, params);
  },

  // 配置应用范围
  setApplicationScope(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/setApplicationScope';
    return HttpUtil.post(url, params);
  },

  // 获取应用配置的范围
  getApplicationScope(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/getApplicationScope';
    return HttpUtil.get(url, params);
  },

  // 获取应用表单列表
  getApplicationLinkFormList(params) {
    if (!params) {
      params = {};
    }
    params.linkType = '1';
    return this.getApplicationLinkDataList(params);
  },

  // 获取应用流程列表
  getApplicationLinkProcessList(params) {
    if (!params) {
      params = {};
    }
    params.linkType = '2';
    return this.getApplicationLinkDataList(params);
  },
  // 获取应用流程列表
  getApplicationLinkReportList(params) {
    if (!params) {
      params = {};
    }
    params.linkType = '3';
    return this.getApplicationLinkDataList(params);
  },

  // 获取应用关联数据
  getApplicationLinkDataList(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/last/version';
    return HttpUtil.get(url, params);
  },
  // 获取应用关联数据-已发布
  getApplicationLinkDataPublished(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/published';
    return HttpUtil.get(url, params);
  },

  // 获取应用关联实例发布状态数据
  getApplicationLinkEntityPublishList(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/link/published';
    return HttpUtil.get(url, params);
  },

  // 删除应用关联
  deleteAppLink(id) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/delete';
    return HttpUtil.get(url, {
      id
    });
  },

  // 发布应用关联
  publishAppLink(id) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/publish';
    return HttpUtil.get(url, {
      id
    });
  },
  //关闭
  closedAppLink(id) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/close';
    return HttpUtil.get(url, {
      id
    }); 
  },
  // 复制应用关联
  copyAppLink(params) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/copy';
    return HttpUtil.get(url, params);
  },

  // 升版应用关联
  upgradeAppLink(id) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/upgrade';
    return HttpUtil.get(url, {
      id
    });
  },
  // 关闭应用关联
  closeAppLink(id) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/close';
    return HttpUtil.get(url, {
      id
    });
  },

  // 发布应用
  publishApp(id) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/publish';
    return HttpUtil.get(url, {
      id
    });
  },

  // 获取流程
  linkQueryLinkPublished(param) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/link/published';
    return HttpUtil.get(url, param);
  },

  // 获取应用树
  queryTypeTree() {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/query/type/tree';
    return HttpUtil.get(url);
  },

  // 获取应用树
  applicationLinkQueryCodeTree(param?: any) {
    let url = process.env.VUE_APP_SERVER_CODE + 'application/link/query/code/tree';
    return HttpUtil.get(url, param);
  }
};
